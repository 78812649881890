import React, { useRef, useState, useEffect } from "react";

function getLabelForValue(value, options) {
  for (const option of options) {
    if (option.value === value) {
      return option.label;
    }
  }
  return null;
}

function SelectComponent({ placeholder, onChange, value, options, icon }) {  
  const [selected, setSelected] = useState(getLabelForValue(value, options));
  const [isOpen, setIsOpen] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);
  const menuRef = useRef(null);

  
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  
  const handleOptionClick = (option) => {
    setSelected(option.label);
    setHasSelection(true);
    setIsOpen(false);
    if (onChange) {
      onChange(option.value);
    }
  };
  
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [isOpen]);

  return (
    <div className="flex justify-center items-center" ref={menuRef}>
      <div className="relative w-full cursor-pointer" onClick={toggleDropdown}>
        <span
          className={`${isOpen ? '-mt-[1px]' : 'mt-0'} absolute bg-white cursor-pointer h-1 left-1.5 px-1 text-transparent transition-all w-fit`}
        >{placeholder}</span>
        <label className={`absolute left-1 -top-[12px] text-sm sm:text-base px-1 transition-all text-blue-gray-600 cursor-pointer`}>
          {placeholder}
        </label>
        <button
          className={`${isOpen ? 'outline-none ring-1 ring-black border-black' : 'border-blue-gray-200'} cursor-pointer w-full h-full bg-transparent text-blue-gray-700 font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50
           disabled:border-0 disabled:cursor-not-allowed transition-all border text-sm px-3 py-2.5 rounded-[7px] bg-white`}
        >
          <span className="flex flex-row items-center gap-x-1 text-base sm:text-lg truncate">
            <span className="hidden sm:block">{icon}</span>
            {selected || ""}
          </span>
          <span className={`absolute right-2 top-0 h-full flex items-center pointer-events-none transition-all ${isOpen ? 'rotate-180' : ''}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4 sm:size-5 bg-white">
              <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
            </svg>
          </span>
        </button>

        {isOpen && (
          <ul className="absolute z-10 transition-all mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className={`mb-1 last:mb-0 select-none relative py-2 pl-3 pr-9 cursor-pointer ${selected === option.label ? 'bg-indigo-600 text-white ' : 'hover:bg-gray-200'}`}
              >
                <span className={`block truncate ${selected === option.label ? "font-medium bg-indigo-600 text-white" : "font-normal"}`}>
                  {option.label}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default SelectComponent;
