import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";
import NavMenu from "../../blocks/NavMenu/NavMenu.jsx";
import Footer from "../../blocks/Footer.jsx";
import Gallery from "../../blocks/Gallery.jsx";
import SocialMedia from "../../blocks/SocialMedia.jsx";
import Rating from "./Rating.jsx";
import RatingAlt from "./RatingAlt.jsx";
import Comments from "../../blocks/Comments.jsx";
// import Languages from "../../blocks/Languages.jsx";
import Staff from "../../blocks/Staff.jsx";
import MapDetails from "../../blocks/MapDetails.jsx";
import FloatingDetails from "../../blocks/FloatingDetails.jsx";
// import Availability from "../../blocks/Availability.jsx";
import Separator from "../../assets/Separator.jsx";
import TagList from "../../components/TagList.jsx";

import { Chip } from "@material-tailwind/react";
import { Button } from "@material-tailwind/react";

function Details() {
  const data = useLoaderData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const TimeFormat = (time) => {
    if (time.includes("Stängt")) {
      return "Stängt";
    }
  
    // Extract the time part and AM/PM part by splitting on space
    let [timePart, ampm] = time.replace(" ", " ").split(" ");
  
    // Split the time part into hours and minutes (if provided)
    let [hours12, minutes] = timePart.split(":");
  
    // If minutes are not provided, default to '00'
    minutes = minutes || '00';
  
    // Convert hours to an integer for easier calculation
    hours12 = parseInt(hours12, 10);
  
    // Convert the 12-hour format to 24-hour format based on AM/PM
    if (ampm === 'pm' && hours12 !== 12) {
      hours12 += 12; // For PM, add 12 to convert to 24-hour time, except when it's 12 PM
    } else if (ampm === 'am' && hours12 === 12) {
      hours12 = 0; // For 12 AM (midnight), set hours to 0
    }
  
    // Return the result in the correct 24-hour format
    return `${hours12}:${minutes.padStart(2, '0')}`;
  };

  function isOpenNow(schedule) {
    const daysMap = {
      "Måndag": "Monday",
      "Tisdag": "Tuesday",
      "Onsdag": "Wednesday",
      "Torsdag": "Thursday",
      "Fredag": "Friday",
      "Lördag": "Saturday",
      "Söndag": "Sunday"
    };
  
    const today = new Date();
    const currentDay = today.toLocaleDateString('en-US', { weekday: 'long' });
  
    const currentSchedule = schedule.find(daySchedule => daysMap[daySchedule.day] === currentDay);
  
    if (!currentSchedule || currentSchedule.openTime === "Stängt") {
      return false;
    }
  
    const openTime = new Date();
    const closeTime = new Date();
  
    const [openHours, openMinutes, openPeriod] = parseTime(currentSchedule.openTime);
    const [closeHours, closeMinutes, closePeriod] = parseTime(currentSchedule.closeTime);
  
    openTime.setHours(openPeriod === "am" ? openHours : openHours + 12, openMinutes, 0, 0);
    closeTime.setHours(closePeriod === "am" ? closeHours : closeHours + 12, closeMinutes, 0, 0);
  
    return today >= openTime && today <= closeTime;
  }
  
  function parseTime(time) {
    const period = time.includes("am") ? "am" : "pm";
    const [hours, minutes] = time.replace(period, "").trim().split(":").map(Number);
    return [hours, minutes || 0, period];
  }

  return (
    <div>
      <div className="z-10 w-full bg-white">
        <NavMenu></NavMenu>
      </div>

      <div className="min-h-screen bg-white text-gray-800">
        {/* Hero section */}
        <div className="w-full bg-[#e8eefc]">
          <div className="mx-auto max-w-4xl lg:max-w-6xl">
            <div className="flex w-full items-center p-12 px-0 md:p-14 lg:p-20">
              <div className="mr-12 hidden md:block">
                <div
                  className="h-40 w-40 rounded-[40px] bg-white bg-contain bg-center bg-no-repeat lg:h-52 lg:w-52"
                  style={{
                    backgroundImage: `url('${data.logo ? `/img/logos/${data.logo.name}.${data.logo.extension}` : "/img/logo-details.svg"}')`,
                  }}
                ></div>
              </div>

              <div className="mx-4 sm:mx-6 md:mx-0">
                <h1 className="mb-2 text-3xl font-black text-dogblue md:text-4xl lg:text-5xl">
                  {data.centerName}
                </h1>
                <div className="align-center flex flex-row items-start">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-7 w-7"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                  </svg>
                  {data.metadata && (
                    <p className="text-md ml-2 md:text-xl">
                      {data.metadata.address}
                    </p>
                  )}
                </div>
                {data.chain != null && (
                  <div class="align-center mb-4 mt-4 flex w-fit flex-row items-center rounded-2xl border-2 border-indigo-500 px-3 py-px text-indigo-500">
                    <p class="text-sm lg:text-lg">Hunddagiskedja</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="relative flex h-full justify-center md:justify-start">
            <div className="mt-4 w-full max-w-[660px] sm:mt-10 md:max-w-full lg:mt-12">
              {/* Rating */}
              <RatingAlt info={data} />

              {/* Services */}
              {data.services && (
                <div className="mt-8">
                  <TagList services={data.services} exploded={true} />
                </div>
              )}

              <div className="block lg:hidden">
                <Separator size="lg" />

                {(data.pricing && data.metadata) && (
                  <FloatingDetails
                    pricing={data.pricing}
                    name={data.metadata.centerName}
                  />
                )}
              </div>

              <Separator size="lg" />

              <div className="mb-20 lg:mt-0">
                <div className="mb-5 text-2xl font-black sm:text-3xl">
                  Om hunddagiset
                </div>

                {data.about?.introParagraphs &&
                  data.about.introParagraphs.map((item, i) => (
                    <p key={`about-${i}`} className="mb-4">
                      {item}
                    </p>
                  ))}

                {data.metadata?.schedule && (
                  <>
                    <div className="mb-3 mt-8 text-xl font-black">
                      Öppettider
                    </div>
                    <div>
                      {data.metadata.schedule.map((item, i) => (
                        <div key={i} className="mb-2 flex flex-row">
                          <div className="w-[100px]">{item.day}:</div>
                          <div
                            className={`flex-1 ${item.openTime === "Stängt" ? "text-red-700" : ""}`}
                          >
                            {TimeFormat(item.openTime)}{" "}
                            {item.closeTime && `- ${TimeFormat(item.closeTime)}`}
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                )}

                {data.typicalDay && (
                  <>
                    <div className="mb-3 mt-8 text-xl font-black">
                      En vanlig dag på hunddagiset
                    </div>
                    <ul className="ml-6 list-disc">
                      {data.typicalDay.map((item, i) => (
                        <li
                          key={i}
                          className={`mb-1 ${item.time.length > 0 ? "" : "list-none py-2"}`}
                        >
                          <span
                            className={`mr-2 font-bold ${item.time.length > 0 ? "" : "hidden"}`}
                          >
                            {item.time}
                          </span>
                          <span className="">{item.description}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}

                {/* <div className="">
                  <div className="mb-5 mt-10 text-xl font-black leading-none">
                    Tillgänglighet
                  </div>
                  <Availability />
                </div> */}

                {/* {data.languages && (
                  <>
                    <div className="mb-3 mt-8 text-xl font-black">Språk</div>
                    <Languages data={data.languages} />
                  </>
                )} */}

                {/* <>
                    <Separator size="lg" />
                    <div className="mb-5 text-2xl font-black sm:text-3xl" id="prices">
                      Prisuppdelning och Tjänster
                    </div>
                    <PriceDetails />
                  </> */}

                {data.staff && (
                  <>
                    <Separator size="lg" />
                    <div className="mb-5 text-2xl font-black sm:text-3xl">
                      Personal
                    </div>
                    <Staff data={data.staff} />
                  </>
                )}

                {data.tags && (
                  <>
                    <Separator size="lg" />
                    <div className="mb-5 text-2xl font-black sm:text-3xl">
                      Behandlingar
                    </div>
                    <div className="flex flex-wrap justify-start">
                      {data.tags.map((tag, i) => (
                        <span
                          key={i}
                          className="mb-3 mr-2 inline-block rounded bg-gray-200 px-2 py-1"
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </>
                )}

                <Separator size="lg" />

                <div id="reviews">
                  <div className="mb-5 scroll-mt-4 text-2xl font-black sm:text-3xl">
                    Recensioner
                  </div>
                  {data.reviews?.length > 0 ? (
                    <>
                      {/* <div className="my-6 flex flex-col justify-between sm:my-12 sm:flex-row">
                        <div>
                          <div className="text-6xl sm:text-7xl">{data.metadata.totalScore}</div>
                          <div href="#reviews" className="text-md mb-6 mt-1 sm:mb-0">
                            Baserat på {data.reviews.length} recensioner
                          </div>
                        </div>
                        <div>
                          <Button variant="outlined">Lämna en recension</Button>
                        </div>
                      </div> */}

                      <div className="my-6 mb-10 sm:my-12 sm:mb-20">
                        <Rating info={data} />
                        <div className="flex justify-end mt-2 text-gray-600">
                          <span className="mr-2">Källa:</span>
                          <span className="italic">Google</span>
                        </div>
                      </div>
                      <Comments data={data.reviews} />
                    </>
                  ) : (
                    <>
                      <div>Inga recensioner</div>
                    </>
                  )}
                </div>

                <Separator size="lg" />

                <div
                  className="mb-5 text-2xl font-black sm:text-3xl"
                  id="contact"
                >
                  Kontakt
                </div>
                <div>
                  {data.metadata?.phone && (
                    <>
                      <Chip
                        value={`${isOpenNow(data.metadata.schedule) ? "öppet nu" : "stängt"}`}
                        size="sm"
                        className={`mb-2 w-fit ${isOpenNow(data.metadata.schedule) ?  "bg-[#dbefdd] text-[#1b5e20]" : "bg-[#efdbdb] text-[#5e1b1b]"}`}
                      />
                      <span className="mr-2">Ring hunddagis:</span>
                      <a
                        className="text-indigo-400 underline"
                        href={`tel:${data.metadata?.phone}`}
                      >
                        {data.metadata?.phone}
                      </a>
                    </>
                  )}
                  {data.metadata?.website && (
                    <div className="mt-6">
                      <Link
                        to={`https://${data.metadata?.website.replace("https://", "").replace("http://", "")}`}
                        target="_blank"
                      >
                        <Button color="indigo" className="text-md" variant="filled">
                          {data.metadata?.website.replace("https://", "").replace("http://", "")}
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>

                {data.socialMedia && (
                  <>
                    <Separator size="lg" />
                    <div className="mb-5 text-2xl font-black sm:text-3xl">
                      Sociala medier
                    </div>
                    <SocialMedia data={data.socialMedia} />
                  </>
                )}

                {data.gallery && (
                  <>
                    <Separator size="lg" />
                    <div className="mb-5 text-2xl font-black sm:text-3xl">
                      Bildgalleri
                    </div>
                    <Gallery data={data.gallery} />
                  </>
                )}

                {data.video && (
                  <>
                    <Separator size="lg" />
                    <div className="mb-5 text-2xl font-black sm:text-3xl">
                      Video
                    </div>
                    <iframe
                      width="100%"
                      height="360"
                      src={data.video}
                      className="rounded-lg"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

                <Separator size="lg" />

                <div className="mb-5 text-2xl font-black sm:text-3xl">
                  Hitta till i Karta
                </div>
                <div className="-mt-4 mb-5 flex items-center justify-between">
                  <span className="text-lg">{data.metadata?.address}</span>
                  <a
                    className="ml-4 text-blue-600"
                    href={"https://maps.google.com/?q=" + data.metadata?.address}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Se karta i google maps"
                  >
                    {/* <Button variant="outlined" className="flex items-center">
                      <span className="mr-2">Öppna i google maps</span>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                      </svg>
                    </Button> */}
                  </a>
                </div>
                <MapDetails
                  coord={data.metadata?.coord}
                  extraClass="static-map"
                />
              </div>
            </div>

            <div className="sticky top-[4rem] mb-20 ml-12 mt-[-8rem] hidden h-max min-w-[450px] rounded-xl border border-gray-300 bg-white p-[1.5rem] shadow-none lg:block xl:ml-32">
              <div className="font-black leading-none mb-4 text-3xl">
                Priser
              </div>
              {(data.metadata && data.pricing) && (
                <FloatingDetails
                  pricing={data.pricing}
                  showButtons={true}
                  url={data.metadata.website}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default Details;
