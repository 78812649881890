import React, { useState } from "react";
import CustomRatingAlt from "./CustomRatingAlt.jsx";
import { Button } from "@material-tailwind/react";

const Comments = ({ data }) => {
  const [displayedComments, setDisplayedComments] = useState(10);
  const [expandedComments, setExpandedComments] = useState({});

  const toggleExpand = (index) => {
    setExpandedComments((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  const icon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-6 w-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
      />
    </svg>
  );

  const showMoreComments = () => {
    setDisplayedComments((prevCount) => prevCount + 10);
  };

  return (
    <div>
      {data.slice(0, displayedComments).map((item, index) => (
        <div key={index}>
          {item.comment ? (
            <div className="mb-16">
              {/* Rating */}
              <div>
                {/* {item.rating && ( */}
                <div className="flex justify-between">
                  <CustomRatingAlt className={"rotate-[30deg] mr-2"} size={6} value={parseFloat(item.stars)} />
                  {/* <CustomRating value={Math.round(item.rating)} /> */}
                  <div className="font-bold">{item.timeAgo}</div>
                </div>
                {/* )} */}
                <div
                  className={`${
                    expandedComments[index] ? "" : "line-clamp-3"
                  } relative mt-3 overflow-hidden whitespace-pre-line break-words`}
                >
                  {expandedComments[index]
                    ? item.comment
                    : item.comment.slice(0, 250) +
                      (item.comment.length > 250 ? "..." : "")}
                </div>

                {item.comment.length > 250 && (
                  <div
                    className="mt-2 cursor-pointer font-bold text-indigo-500 opacity-80 hover:opacity-100"
                    onClick={() => toggleExpand(index)}
                  >
                    {expandedComments[index] ? "Läs mindre" : "Läs mer"}
                  </div>
                )}
                <div className="mt-5 font-semibold capitalize">{`${item.name}`}</div>
              </div>

              {/* Answer */}
              {item.answer && (
                <div className="leading-1.5 ml-0 mt-3 rounded-[30px] rounded-tl-[0px] bg-blue-50 bg-opacity-50 px-5 py-5 text-base font-normal sm:ml-5 sm:mt-5">
                  <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                    <div className="flex flex-row items-center text-gray-900">
                      <div className="mr-2 w-4">{icon}</div>
                      <div className="flex flex-1 items-center pl-1 font-bold tracking-normal">
                        Svar från hunddagis
                      </div>
                    </div>
                    <div className="mt-2 flex justify-end font-bold tracking-normal text-gray-700 sm:mt-0">
                      {item.answerTimeAgo}
                    </div>
                  </div>
                  <div className="mt-3 break-words text-gray-700">
                    {item.answer}
                  </div>
                </div>
              )}
            </div>
          ) : (
            // No comment
            <div className="mb-16">
              <div className="flex justify-between">
                <CustomRatingAlt className={"rotate-[30deg] mr-2"} size={6} value={parseFloat(item.stars)} />
                <div className="font-bold">{item.timeAgo}</div>
              </div>
              <div className="mt-5 font-semibold capitalize">{`${item.name}`}</div>
            </div>
          )}

        </div>
      ))}

      {displayedComments < data.length && (

        <span className="block cursor-pointer text-center" onClick={showMoreComments}>
          <Button className="text-[16px]" variant="outlined">Visa fler recensioner</Button>
        </span>
      )}
    </div>
  );
};

export default Comments;
