import React from "react";
import NavMenu from "../../blocks/NavMenu/NavMenu.jsx";
import Footer from "../../blocks/Footer.jsx";

const AboutUs = () => {
  return (
    <div>
      <div className="z-10 w-full bg-white">
        <NavMenu></NavMenu>
      </div>

      <div className="min-h-screen bg-white pb-20 text-gray-800">
        {/* Hero section */}
        <div
          class="h-[500px] w-full bg-gray-100 bg-cover bg-center bg-no-repeat"
          style={{backgroundPositionY: '-560px', backgroundImage: "url(/img/bg/omoss.jpg)"}}
        >
          <div className="absolute bg-[#4050b5] opacity-50 h-[500px] top-[88px] w-full"></div>

          <div className="flex h-full max-w-7xl mx-auto">
            <div className="flex items-end w-full">
              <h1 className="absolute font-bold mb-14 text-7xl text-white">Om oss</h1>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="relative flex h-full justify-center md:justify-start">
            <div className="mt-4 w-full max-w-[860px] sm:mt-10 md:max-w-full lg:mt-12">

              <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                Vår filosofi
              </h2>
              <p className="mb-6 text-lg">
                På <span className="font-semibold">hunddagis.info</span> tror vi
                att varje hund förtjänar den bästa omsorgen och att varje
                hundägare förtjänar sinnesro. Vårt uppdrag är att göra det
                enklare och mer tillgängligt att hitta högkvalitativa
                hundvårdscenter i Sverige. Oavsett om du letar efter en pålitlig
                dagisplats, professionella trimningstjänster eller
                veterinärvård, har vi allt du behöver.
              </p>

              <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                Vilka vi är
              </h2>
              <p className="mb-6 text-lg">
                <span className="font-semibold">hunddagis.info</span> är en
                omfattande metasökmotor som är skapad av hundälskare för
                hundälskare. Vi förstår hur viktig din hund är för dig, och vi
                vill säkerställa att du hittar den perfekta vårdlösningen som
                passar din hunds behov.
              </p>

              <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                Vad vi gör
              </h2>
              <ul className="mb-6 list-disc text-lg list-outside pl-6">
                <li>
                  <b>Dagvårdstjänster</b>: Heltids- och deltidslösningar för hunddagis som passar din hektiska vardag.
                </li>
                <li>
                  <b>Trimning</b>: Hitta de bästa trim- och groomingtjänsterna för att hålla din hund ren och fin.
                </li>
                <li>
                  <b>Veterinärtjänster</b>: Hitta lätt närliggande veterinärkliniker för rutinkontroller eller akuta besök.
                </li>
                <li>
                  <b>Kurser</b>: Upptäck hundträningskurser för att förbättra beteende och lydnad.
                </li>
                <li>
                  <b>Butiker</b>: Hitta lokala och online hundbutiker för foder och tillbehör.
                </li>
                <li>
                  <b>Rehabilitering</b>: Hitta specialiserade center för hundrehabilitering.
                </li>
                <li>
                  <b>Långtidsvård (Hundhotell)</b>: Vi har listor över pålitliga långtidsdagvårdsanläggningar.
                </li>
              </ul>

              <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                Varför välja oss?
              </h2>
              <ul className="mb-6 list-disc text-lg list-outside pl-6">
                <li>
                  <b>Omfattande Listor</b>: Vi tillhandahåller en omfattande lista över
                  verifierade hundvårdscenter i Sverige.
                </li>
                <li>
                  <b>Enkel Jämförelse</b>: Jämför tjänster, priser och recensioner på
                  ett ställe.
                </li>
                <li>
                  <b>Uppdaterad Information</b>: Våra listor uppdateras regelbundet.
                </li>
                <li>
                  <b>Personlig Sökning</b>: Filtrera resultat efter plats, prisklass
                  eller specifika tjänster.
                </li>
              </ul>

              <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                Vår historia
              </h2>
              <p className="mb-6 text-lg">
                Grundat år <span className="font-semibold">2024</span>, föddes{" "}
                <span className="font-semibold">hunddagis.info</span> ur
                frustrationen över att försöka hitta pålitlig hundvård. Som
                hundägare själva visste vi att det måste finnas ett bättre sätt
                att koppla samman med rätt vårdgivare.
              </p>

              <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                Våra värderingar
              </h2>
              <ul className="mb-6 list-disc text-lg list-outside pl-6">
                <li>
                  <b>Förtroende</b>: Vi listar endast pålitliga hundvårdscenter för att
                  säkerställa att din hund är i trygga händer.
                </li>
                <li><b>Transparens</b>: Alla tjänster och priser visas tydligt.</li>
                <li>
                  <b>Omsorg</b>: Vi brinner för att ge det bästa till hundar och deras
                  ägare.
                </li>
              </ul>

              <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                Gå med i vår gemenskap
              </h2>
              <p className="text-lg">
                Vi är mer än bara en plattform; vi är en gemenskap av
                hundälskare. Följ oss på sociala medier, dela dina erfarenheter
                och hjälp andra hundägare att hitta den perfekta vården för sina
                husdjur.
              </p>
            </div>

            <div className="sticky top-[4rem] mb-20 ml-12 mt-[-8rem] hidden h-max min-w-[250px] rounded-xl bg-transparent p-[1.5rem] shadow-none lg:block xl:ml-32">
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default AboutUs;
