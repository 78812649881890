import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import { Link } from "react-router-dom";
import NavMenu from "src/blocks/NavMenu/NavMenu.jsx";
import Footer from "src/blocks/Footer.jsx";
import EditItem from "./EditItem_new";

import {
  MagnifyingGlassIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { PencilIcon, UserPlusIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Avatar,
  IconButton,
  Tooltip,
  Dialog,
} from "@material-tailwind/react";

const TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Monitored",
    value: "monitored",
  },
  {
    label: "Unmonitored",
    value: "unmonitored",
  },
];

const TABLE_HEAD = ["Hunddagis", "Completion", "Contact", "Location", "Price (Heltid)", "About", "Typical Day", "Services", "Staff", "Social Media", "Gallery",  "Video", ""];

function convertToFileName(str) {
  const cleanStr = str.replace(/[^\w\säöå]/gi, "");// Remove special characters except spaces
  const trimmedStr = cleanStr.replace(/\s+/g, " ");// Replace multiple spaces with one space
  const fileName = trimmedStr.replace(/\s/g, "-");// Replace spaces with dashes
  return fileName.toLowerCase();
}

function Admin() {

  const {data, pricing, pricing_ver} = useLoaderData();
  console.log("data", data);
  console.log("pricing", pricing);
  console.log("pricing_ver", pricing_ver);


  let dataSorted = data.sort((a, b) => getCompletion(b) - getCompletion(a));
  const [open, setOpen] = React.useState(false);
  const [popupItem, setPopupItem] = useState(null);

  function getCompletion(item) {
    let completionScore = 0;
    // It has reviews
    if (item.reviews.length > 0) {
      completionScore++;
    }
    // It has a totalScore
    if (item.metadata.totalScore != null) {
      completionScore++;
    }
    // It has an address
    if (item.metadata.address != null) {
      completionScore++;
    }
    // It has an coord
    if (item.metadata.coord != null) {
      completionScore++;
    }
    // It has a price
    if (item.pricing != null) {
      completionScore++;
    }
    // It has a about
    if (item.about != null) {
      completionScore++;
    }
    // It has a typical day
    if (item.typicalDay != null) {
      completionScore++;
    }
    // It has a service
    if (item.services != null) {
      completionScore++;
    }
    // It has a staff
    if (item.staff != null) {
      completionScore++;
    }
    // It has a social media
    if (item.socialMedia != null) {
      completionScore++;
    }
    // It has a gallery
    if (item.gallery != null) {
      completionScore++;
    }
    // It has a video
    if (item.video != null) {
      completionScore++;
    }

    return completionScore;
  }

  function handleOpen(item) {
    setOpen(!open)
    setPopupItem(item);
  }

  return (
    <div>
      <div className="sticky top-0 z-50 w-full bg-gray-50">
        <NavMenu />
      </div>

        <Dialog open={open} handler={() => setOpen(!open)} size="xl">
          <>
            {!popupItem ? (<span>empty</span>) : ''}
            {popupItem && (
             <EditItem data={popupItem} pricing={pricing} pricing_ver={pricing_ver} />
            )}
          </>
        </Dialog>

      <div className="w-full">
        <div className="mx-10">
          <Card className="h-full w-full">
            <CardHeader floated={false} shadow={false} className="rounded-none">
              <div className="mb-8 flex items-center justify-between gap-8">
                <div>
                  <Typography variant="h4" color="blue-gray">
                    Hunddagis list – {data.length} members
                  </Typography>
                  <Typography color="gray" className="mt-1 font-normal">
                    See information about all members
                  </Typography>
                </div>
                <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                  {/* <Button variant="outlined" size="sm" className="text-md">
                    view all
                  </Button> */}
                  <Button className="flex items-center gap-3 text-md" size="sm">
                    <UserPlusIcon strokeWidth={2} className="h-4 w-4" /> Add
                    member
                  </Button>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                <Tabs value="all" className="w-full md:w-max">
                  <TabsHeader>
                    {TABS.map(({ label, value }) => (
                      <Tab key={value} value={value}>
                        &nbsp;&nbsp;{label}&nbsp;&nbsp;
                      </Tab>
                    ))}
                  </TabsHeader>
                </Tabs>
                <div className="w-full md:w-72">
                  <Input
                    label="Search"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>
            <CardBody className="overflow-scroll px-0 h-[70vh] p-0 mt-4">
              <table className="w-full min-w-max table-auto text-left">
                <thead className="sticky -top-[1px]  z-10 bg-gray-50">
                  <tr>
                    {TABLE_HEAD.map((head, index) => (
                      <th
                        key={head}
                        className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                        >
                          {head}{" "}
                          {index !== TABLE_HEAD.length - 1 && (
                            <ChevronUpDownIcon
                              strokeWidth={2}
                              className="h-4 w-4"
                            />
                          )}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dataSorted.map(
                    (item, index) => {
                      const isLast = index === dataSorted.length - 1;
                      const classes = isLast
                        ? "p-4"
                        : "p-4 border-b border-blue-gray-50";

                      return (
                        <tr key={item.centerName} className="hover:bg-blue-gray-50/50">
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <Link to={`/hunddagis/${convertToFileName(item.centerName)}`}>
                                <Avatar src={`${item.logo ? `/img/logos/${item.logo.name}.${item.logo.extension}` : "/img/logo-details.svg"}`} alt={item.centerName} size="sm" />
                              </Link>
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  <a className="hover:underline" href={`https://${item.metadata.website}`} target="_blank">{item.centerName}</a>
                                  <span>{item.metadata.website ? '' : <span className="text-red-500 ml-1">no website</span>}</span>
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal opacity-70"
                                >
                                  {item.metadata.totalScore ? 
                                  <>score: {item.metadata.totalScore} – {item.reviews.length} reviews</>
                                  :
                                  <span className="text-red-500">no score</span>
                                  } 
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {getCompletion(item)}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {item.metadata.phone ? item.metadata.phone : <span className="text-red-500">no phone</span>}
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal opacity-70"
                              >
                                {item.metadata.email ? item.metadata.email : <span className="text-red-500">no email</span>}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal opacity-70"
                              >
                                {item.metadata.coord ?
                                <span>{parseFloat(item.metadata.coord.latitude).toFixed(2)}..., {parseFloat(item.metadata.coord.longitude).toFixed(2)}...</span> : <span className="text-red-500">no coord</span>}
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal opacity-70"
                              >
                                {item.metadata.address ?
                                <span>{item.metadata.address.substring(0,12)}...</span> : <span className="text-red-500">no address</span>}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex flex-col">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {item.pricing != null ? item.pricing.fullprice : <span className="text-red-500">no price</span>}
                              </Typography>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal opacity-70"
                              >
                                {item.pricing != null ? "kr/mån" : ""}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                size="sm"
                                value={item.about != null ? "true" : "false"}
                                color={item.about != null ? "green" : "red"}
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                size="sm"
                                value={item.typicalDay != null ? "true" : "false"}
                                color={item.typicalDay != null ? "green" : "red"}
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                size="sm"
                                value={item.services != null ? "true" : "false"}
                                color={item.services != null ? "green" : "red"}
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                size="sm"
                                value={item.staff != null ? "true" : "false"}
                                color={item.staff != null ? "green" : "red"}
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                size="sm"
                                value={item.socialMedia != null ? "true" : "false"}
                                color={item.socialMedia != null ? "green" : "red"}
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                size="sm"
                                value={item.gallery != null ? "true" : "false"}
                                color={item.gallery != null ? "green" : "red"}
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="w-max">
                              <Chip
                                variant="ghost"
                                size="sm"
                                value={item.video != null ? "true" : "false"}
                                color={item.video != null ? "green" : "red"}
                              />
                            </div>
                          </td>
                          <td className={classes}>
                            <Tooltip content="Edit Hunddagis">
                              <IconButton variant="text" onClick={() => handleOpen(item)}>
                                <PencilIcon className="h-4 w-4" />
                              </IconButton>
                            </Tooltip>
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </CardBody>
            {/* <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                Page 1 of 10
              </Typography>
              <div className="flex gap-2">
                <Button variant="outlined" size="sm">
                  Previous
                </Button>
                <Button variant="outlined" size="sm">
                  Next
                </Button>
              </div>
            </CardFooter> */}
          </Card>
        </div>
      </div>

      {/* <Footer extraClasses={"bg-[#f0f0f0] mt-20"}></Footer> */}
    </div>
  );
}

export default Admin;
