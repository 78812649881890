export default function Staff({ data }) {
  return (
    <div className="relative grid grid-cols-1 sm:grid-cols-2 gap-x-8 gap-y-8">
      {data.map((staff, i) => (
        <div key={i} className="relative flex h-full max-h-[170px] w-full overflow-auto bg-white">
          {staff.pic ? (
            <div
              className="mr-4 h-16 w-16 rounded-full border border-gray-300 bg-cover bg-center"
              style={{
                backgroundImage: `url(${staff.pic})`,
                backgroundSize: '120%'
              }}
            ></div>
          ) : (
            <div className="mr-4 h-16 w-16 rounded-full border border-gray-300 bg-cover bg-center">
              <div className="flex h-full w-full items-center justify-center rounded-full bg-gray-800 text-[24px] font-bold text-white">
                <span>{staff.name[0]}</span>
                <span className="ml-px">{staff.surname[0]}</span>
              </div>
            </div>
          )}
          <div className="relative flex flex-1 flex-col justify-center">
            <h2 className="leading-1.5 text-base font-bold">
              {staff.name} {staff.surname}
            </h2>
            <h3 className="leading-1.5 text-base font-normal">{staff.role}</h3>
          </div>
        </div>
      ))}
    </div>
  );
}
