import React from "react";
import ProgressBar from "../../components/ProgressBar.jsx";
import CustomRatingAlt from "../../blocks/CustomRatingAlt.jsx";
import Paw from "../../assets/Paw.jsx";

const Rating = ({ info }) => {
  let totalStars = info.reviews.length;
  let total5stars = 0;
  let total4stars = 0;
  let total3stars = 0;
  let total2stars = 0;
  let total1stars = 0;

  info.reviews.forEach((review) => {
    if (review.stars.includes("5")) {
      total5stars += 1;
    } else if (review.stars.includes("4")) {
      total4stars += 1;
    } else if (review.stars.includes("3")) {
      total3stars += 1;
    } else if (review.stars.includes("2")) {
      total2stars += 1;
    } else if (review.stars.includes("1")) {
      total1stars += 1;
    }
  });

  return (
    <>
      <div className="flex flex-col sm:flex-row">
        <div className="mb-4 sm:mb-0">
          <div>OMDÖME</div>
          <div className="text-7xl font-bold">{info.metadata.totalScore}</div>
          <div className="my-2 flex justify-between">
            <CustomRatingAlt
              className={"-mt-[5px] mr-1 rotate-[30deg]"}
              size={5}
              value={Math.round(parseFloat(info.metadata.totalScore))}
            />
          </div>
          <div className="text-base">
            {info.reviews.length} recensioner
          </div>
        </div>

        <div className="mx-3 w-px rounded-lg bg-gray-300 sm:block md:mx-12 lg:mx-3"></div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="mb-1 flex flex-row sm:mb-0 items-center">
            <div className="text-lg mr-2 flex items-center">5</div>
            <div className="text-base mr-2 flex items-center text-dogpink"><Paw size={7} extraClasses={'mr-1'} /></div>
            <ProgressBar value={total5stars/totalStars * 100} />
            <div className="text-lg ml-2 flex items-center w-10">{total5stars}</div>
          </div>
          <div className="mb-1 flex flex-row sm:mb-0 items-center">
            <div className="text-lg mr-2 flex items-center">4</div>
            <div className="text-base mr-2 flex items-center text-dogpink"><Paw size={7} extraClasses={'mr-1'} /></div>
            <ProgressBar value={total4stars/totalStars * 100} />
            <div className="text-lg ml-2 flex items-center w-10">{total4stars}</div>
          </div>
          <div className="mb-1 flex flex-row sm:mb-0 items-center">
            <div className="text-lg mr-2 flex items-center">3</div>
            <div className="text-base mr-2 flex items-center text-dogpink"><Paw size={7} extraClasses={'mr-1'} /></div>
            <ProgressBar value={total3stars/totalStars * 100} />
            <div className="text-lg ml-2 flex items-center w-10">{total3stars}</div>
          </div>
          <div className="mb-1 flex flex-row sm:mb-0 items-center">
            <div className="text-lg mr-2 flex items-center">2</div>
            <div className="text-base mr-2 flex items-center text-dogpink"><Paw size={7} extraClasses={'mr-1'} /></div>
            <ProgressBar value={total2stars/totalStars * 100} />
            <div className="text-lg ml-2 flex items-center w-10">{total2stars}</div>
          </div>
          <div className="mb-1 flex flex-row sm:mb-0 items-center">
            <div className="text-lg mr-2 flex items-center">1</div>
            <div className="text-base mr-2 flex items-center text-dogpink"><Paw size={7} extraClasses={'mr-1'} /></div>
            <ProgressBar value={total1stars/totalStars * 100} />
            <div className="text-lg ml-2 flex items-center w-10">{total1stars}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rating;
