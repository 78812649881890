import { Rating } from "@material-tailwind/react";
import Paw from "../assets/Paw";

function CustomRatingAlt({ value, className, size, msize }) {
  return (
    <Rating
      ratedColor="red"
      value={value}
      readonly
      ratedIcon={
        <div className={className}>
          <Paw size={size} msize={msize} fill={"#EA2A70"} />
        </div>
      }
      unratedIcon={
        <div className={className}>
          <Paw size={size} msize={msize} fill={"#E0E0E0"} />
        </div>
      }
    />
  );
}

export default CustomRatingAlt;
