import React from "react";
import NavMenu from "../../blocks/NavMenu/NavMenu.jsx";
import Footer from "../../blocks/Footer.jsx";

const Terms = () => {
  return (
    <div>
      <div className="z-10 w-full bg-white">
        <NavMenu></NavMenu>
      </div>

      <div className="min-h-screen bg-white pb-20 text-gray-800">
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="relative flex h-full justify-center md:justify-start">
            <div className="mt-4 w-full max-w-[860px] sm:mt-10 md:max-w-full lg:mt-12">
              <h1 className="mb-14 text-5xl font-bold">Användarvillkor</h1>
              <p className="mb-4 text-lg">Senast uppdaterad: 2024-10-07</p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                1. Introduktion
              </h2>
              <p className="mb-4 text-lg">
                Välkommen till hunddagis.info (“vi”, “vår” eller “oss”). Genom
                att komma åt eller använda vår webbplats (“Tjänsten”) godkänner
                du att följa och vara bunden av följande användarvillkor. Läs
                dessa noggrant innan du använder Tjänsten.
              </p>
              <p className="mb-4 text-lg">
                Om du inte godkänner dessa villkor bör du inte använda Tjänsten.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                2. Användning av tjänsten
              </h2>
              <p className="mb-4 text-lg">
                Du får endast komma åt och använda Tjänsten för personliga och
                icke-kommersiella ändamål. Genom att använda vår Tjänst
                godkänner du att:
              </p>
              <ul className="mb-6 list-outside list-disc pl-6 text-lg">
                <li>Använda webbplatsen för lagliga ändamål.</li>
                <li>
                  Inte delta i någon aktivitet som stör eller skadar
                  webbplatsens prestanda eller säkerhet.
                </li>
                <li>
                  Inte använda innehållet på webbplatsen på ett sätt som kan
                  skada, inaktivera eller försämra webbplatsens funktionalitet.
                </li>
              </ul>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                3. Innehåll och noggrannhet
              </h2>
              <p className="mb-4 text-lg">
                Vi tillhandahåller information om hunddagis, inklusive priser,
                tjänster, recensioner, bilder och personalinformation, för att
                hjälpa användare att fatta välgrundade beslut. Vissa av
                recensionerna, bilderna och personalinformationen som visas på
                vår plattform hämtas från tredje parts webbplatser, inklusive
                Google och andra plattformar.
              </p>
              <p className="mb-4 text-lg">
                Även om vi strävar efter att säkerställa att informationen är
                korrekt och relevant, kontrollerar vi inte noggrannheten eller
                äganderätten till tredje parts recensioner, bilder eller
                personalinformation. Hunddagisen och deras användare ansvarar
                för att hålla denna information korrekt och uppdaterad. Vi
                rekommenderar att du verifierar detaljer direkt med hunddagisen
                innan du fattar beslut baserat på innehållet.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                4. Användargenererat innehåll
              </h2>
              <p className="mb-4 text-lg">
                Vissa delar av vår webbplats kan tillåta dig att lämna
                recensioner eller feedback om hunddagis. Genom att skicka in
                innehåll till vår webbplats godkänner du att:
              </p>
              <li>
                Säkerställa att dina inlägg är ärliga, respektfulla och
                relevanta.
              </li>
              <li>
                Ge oss en icke-exklusiv, royaltyfri, världsomfattande licens att
                använda, ändra och visa ditt innehåll på vår plattform.
              </li>
              <li>
                Inte publicera något innehåll som är stötande, ärekränkande
                eller kränker andras rättigheter.
              </li>
              <p className="mb-4 text-lg">
                Vi förbehåller oss rätten att ta bort användargenererat innehåll
                som bryter mot dessa villkor eller på annat sätt är olämpligt.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                5. Immateriella rättigheter
              </h2>
              <p className="mb-4 text-lg">
                Allt innehåll på webbplatsen, inklusive text, bilder, logotyper
                och grafik, tillhör hunddagis.info eller dess licensgivare. Du
                får inte använda, reproducera eller distribuera något innehåll
                från webbplatsen utan vårt uttryckliga tillstånd.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                6. Tredjepartsrecensioner, bilder och personalinformation
              </h2>
              <p className="mb-4 text-lg">
                Vi samlar in recensioner från tredjepartsplattformar såsom
                Google och andra webbplatser för att ge ytterligare insikter om
                hunddagisen. Dessa recensioner presenteras endast i
                informationssyfte, och vi ansvarar inte för, bekräftar eller tar
                ansvar för riktigheten i recensionerna.
              </p>
              <p className="mb-4 text-lg">
                Vi visar även galleribilder som tillhandahålls av hunddagisen
                eller hämtas från tredjepartsplattformar. Vi äger inte dessa
                bilder och deras användning är föremål för de aktuella
                plattformarnas eller hunddagisens villkor.
              </p>
              <p className="mb-4 text-lg">
                Vi visar även information om personalen, inklusive namn och
                roller, baserat på offentligt tillgänglig information från
                hunddagisens egna webbplatser. Vi ansvarar inte för att
                verifiera riktigheten i denna information.
              </p>
              <p className="mb-4 text-lg">
                Genom att använda vår tjänst godkänner du att:
              </p>
              <ul className="mb-6 list-outside list-disc pl-6 text-lg">
                <li>
                  Vi inte ansvarar för några felaktigheter eller felaktiga
                  framställningar i recensioner, bilder eller
                  personalinformation som hämtas från tredjepartsplattformar.
                </li>
                <li>
                  Vi inte ansvarar för att verifiera äganderätten eller
                  tillstånden relaterade till de galleribilder som visas på
                  plattformen.
                </li>
                <li>
                  Hunddagisen ansvarar för att hålla informationen om personalen
                  uppdaterad.
                </li>
                <li>
                  Om du märker några problem med recensioner, bilder eller
                  personalinformation kan du rapportera det till oss så kommer
                  vi att vidta lämpliga åtgärder där det är möjligt.
                </li>
              </ul>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                7. Begränsning av ansvar
              </h2>
              <p className="mb-4 text-lg">
                I den utsträckning som tillåts enligt lag avsäger vi oss allt
                ansvar för skador som uppstår från din användning av Tjänsten
                eller ditt förlitande på någon information som tillhandahålls på
                webbplatsen. Detta inkluderar, utan begränsning, direkta,
                indirekta, tillfälliga eller följdskador.
              </p>
              <p className="mb-4 text-lg">
                hunddagis.info är en informationsplattform. Vi ansvarar inte för
                handlingar, tjänster eller policys från något hunddagis som
                listas på vår webbplats.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                8. Ändringar i villkoren
              </h2>
              <p className="mb-4 text-lg">
                Vi förbehåller oss rätten att när som helst ändra dessa
                Användarvillkor. Alla ändringar träder i kraft omedelbart när de
                uppdaterade villkoren publiceras på vår webbplats. Din fortsatta
                användning av Tjänsten efter eventuella ändringar innebär att du
                godkänner de uppdaterade villkoren.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                9. Uppsägning
              </h2>
              <p className="mb-4 text-lg">
                Vi förbehåller oss rätten att tillfälligt stänga av eller
                avsluta din tillgång till Tjänsten om du bryter mot dessa
                Användarvillkor eller deltar i något beteende som vi anser är
                skadligt för webbplatsen eller dess användare.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                10. Sekretess
              </h2>
              <p className="mb-4 text-lg">
                Din användning av webbplatsen regleras även av vår
                Integritetspolicy, som beskriver hur vi samlar in, använder och
                skyddar dina personuppgifter. Genom att använda webbplatsen
                samtycker du till insamlingen och användningen av information
                enligt vad som beskrivs i Integritetspolicyn.
              </p>
              <p className="mb-4 text-lg">
                Observera att medan vi visar recensioner och bilder från
                tredjepartsplattformar, omfattas all personlig information som
                finns i dessa recensioner eller bilder av sekretesspolicyn för
                den ursprungliga plattformen, såsom Google. Vi rekommenderar att
                du granskar sekretesspolicyn för dessa plattformar för att
                förstå hur dina data samlas in, används och delas.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                11. Tillämplig lag
              </h2>
              <p className="mb-4 text-lg">
                Dessa Användarvillkor regleras av lagarna i Sverige, utan hänsyn
                till dess lagvalsprinciper. Eventuella tvister som uppstår i
                samband med dessa villkor ska lösas i domstolarna i Sverige.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                12. Kontaktinformation
              </h2>
              <p className="mb-4 text-lg">
                Om du har några frågor eller funderingar kring dessa
                Användarvillkor, vänligen kontakta oss på e-post:{" "}
                <a href="mailto:hej@hunddagis.info" className="text-dogblue hover:underline">hej@hunddagis.info</a>
              </p>
            </div>

            <div className="sticky top-[4rem] mb-20 ml-12 mt-[-8rem] hidden h-max min-w-[250px] rounded-xl bg-transparent p-[1.5rem] shadow-none lg:block xl:ml-32"></div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Terms;
