import L from "leaflet";
import { MapContainer } from "react-leaflet";
import { TileLayer } from "react-leaflet";
import { Marker } from "react-leaflet";
import { ZoomControl } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import customMarkerIcon from "../assets/mapMarker.svg";

export default function MapDetails({ coord, extraClass }) {
  const customIcon = L.icon({
    iconUrl: customMarkerIcon,
    iconSize: [60, 60],
    iconAnchor: [30, 60],
  });

  if(!extraClass){
    extraClass = "";
  }

  return (
    <MapContainer
      className={`rounded-xl ${extraClass}`}
      center={[
        coord?.latitude,
        coord?.longitude,
      ]}
      zoom={12}
      style={{ height: "400px", width: "100%" }}
      scrollWheelZoom={false}
      zoomControl={false}
      dragging={false}
    >
      <TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" // light
        // url ="http://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png" // dark
        // url="http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" // dark
        // url="http://services.arcgisonline.com/arcgis/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}" // light
      />
      <Marker
        position={[
          coord?.latitude,
          coord?.longitude,
        ]}
        icon={customIcon}
      >
      </Marker>
      {/* <CustomZoomControl position="topright" /> */}
      <ZoomControl position="topright" />
    </MapContainer>
  );
}
