import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../db/firebase.js"; // Adjust the import path if needed
import NavMenu from "../../blocks/NavMenu/NavMenu.jsx";
import Footer from "../../blocks/Footer.jsx";

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await addDoc(collection(db, "contact"), formData);
      setStatus(<div className="text-green-700 text-xl">Tack! Vi återkommer till dig så snart som möjligt.</div>);
      setFormData({ name: "", email: "", message: "" });
    } catch (error) {
      setStatus(<div className="text-red-700 text-xl">Något gick fel. Vänligen försök igen senare.</div>);
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div>
      <div className="z-10 w-full bg-white">
        <NavMenu />
      </div>
      <div className="min-h-screen bg-white pb-20 text-gray-800">
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="relative flex h-full justify-center md:justify-start">
            <div className="mt-4 w-full max-w-[860px] sm:mt-10 md:max-w-full lg:mt-12">
              <h1 className="mb-14 text-5xl font-bold">
                Kontakta oss – Hunddagis Stockholm
              </h1>
              <p className="mb-8 max-w-xl text-2xl text-gray-600">
                Har du frågor om hunddagis i Stockholm eller vill du veta mer om
                hur vi kan hjälpa dig?
              </p>

              <p className="mb-8 max-w-xl text-lg text-gray-600">
                Skicka gärna ett mejl till oss på{" "}
                <a
                  href="mailto:hej@hunddagis.info"
                  className="text-dogblue hover:underline"
                >
                  hej@hunddagis.info
                </a>{" "}
                eller använd formuläret så återkommer vi så snart som möjligt!
              </p>

              <div className="mb-12 w-full max-w-2xl rounded-lg bg-white p-6 py-8 shadow-xl">
                <h2 className="mb-4 text-2xl font-semibold text-gray-800">
                  Skicka ett meddelande
                </h2>

                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label htmlFor="name" className="block text-gray-700">
                      Namn
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Ditt Namn"
                      className="w-full rounded-md border border-gray-300 px-4 py-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="block text-gray-700">
                      E-post
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Din E-post"
                      className="w-full rounded-md border border-gray-300 px-4 py-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="block text-gray-700">
                      Meddelande
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Ditt Meddelande..."
                      className="w-full rounded-md border border-gray-300 px-4 py-2 focus:border-blue-500 focus:ring focus:ring-blue-200"
                      required
                    ></textarea>
                  </div>
                  {status ?
                    <p className="mt-4">{status}</p> 
                  :
                  <button
                    type="submit"
                    className="w-full rounded-md bg-dogblue px-4 py-2 font-semibold text-white hover:bg-black"
                  >
                    <span className="flex gap-x-2 justify-center">
                      <span>Skicka Meddelande</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                        />
                      </svg>
                    </span>
                  </button>
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
