import { Button } from "@material-tailwind/react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import TagList from "../components/TagList.jsx";

export default function FloatingDetails({ pricing, services, showButtons, url, exploded, name }) {
  // console.log("name, pricing.fullprice", name, pricing.fullprice);
  return (
    <>
      <div className="flex flex-col">

        {services && (
          <div className="my-4">
            <TagList services={services} exploded={exploded} />
          </div>
        )}

        {pricing && (
          <div className="hover:outline-solid mx-[-0.5rem] flex items-center gap-1 lg:py-4 px-2 py-[0.50rem]">
            <div className="flex flex-1 items-end justify-between ">
              <div className="block">
                <div className="font-bold text-gray-600 uppercase text-xs">Pris från</div>
                <div className="font-bold text-2xl text-gray-900">Heltid</div>
                {/* <div className="font-bold text-gray-800 uppercase text-xs">Heltid</div>
                <div className="font-black text-gray-900 text-xl">Pris från</div> */}
              </div>
              {/* <div className="flex flex-row">
                <div className="text-indigo-500 text-3xl mr-1 sm:mr-2">5</div>
                <div className="overflow-wrap-anywhere max-w-[200px] text-gray-600 flex flex-col item">
                  <span className="text-indigo-600 text-sm">dagar</span>
                  <span className="pl-0 text-gray-500 -mt-1 text-sm sm:text-sm">per vecka</span>
                </div>
              </div> */}
              <div className="flex flex-col items-center justify-center leading-relaxed">
                <div className="flex flex-row items-baseline">
                  <div className="font-black text-3xl text-dogblue">{pricing.fullprice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} kr</div>
                  <div className="text-sm text-gray-600 ml-2">
                    / mån
                  </div>
                </div>
              </div>
              {/* <div className="max-w-[100px] sm:w-full">
                <DialogForm name={name} />
              </div> */}
            </div>
          </div>
        )}

        {/* {showIncludes && (
          <div>
            <div className="my-3 text-lg font-black leading-none">Ingår i alla paket</div>
            <ul className="flex flex-col">
              <li className="flex items-center text-gray-600 mb-2">
                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-indigo-400 text-white rounded-full flex-shrink-0">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                <span>Minst 2 långpromenader per dag</span>
              </li>
              <li className="flex items-center text-gray-600 mb-2">
                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-indigo-400 text-white rounded-full flex-shrink-0">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                <span>Delat rum med vald grupp</span>
              </li>
              <li className="flex items-center text-gray-600 mb-2">
                <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-indigo-400 text-white rounded-full flex-shrink-0">
                  <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" className="w-3 h-3" viewBox="0 0 24 24">
                    <path d="M20 6L9 17l-5-5"></path>
                  </svg>
                </span>
                <span>Social stimulans</span>
              </li>
            </ul>
          </div>
        )} */}

        {/* <div className="text-gray-500 text-left mt-3 mb-0 sm:-mb-1">Alla priser inkl. 25% moms</div> */}

        {showButtons && (
          <div className="flex flex-col mt-4 space-x-0 space-y-4 md:space-y-0 md:flex-row md:space-x-4 lg:flex-col lg:space-x-0 lg:space-y-4 w-full">
            {url && (
              <Link to={`https://${url}`} target="_blank"><Button  size="lg" className="!h-[55px] flex-1 w-full text-[18px]" color="indigo" variant="filled">Webbsida</Button></Link>
            )}

            <HashLink smooth to="#contact" className="w-full flex-1">
              <Button  className="!h-[55px] w-full flex-1 text-[18px]" size="lg" color="indigo" variant={url ? "outlined" : "filled"}>Kontakt</Button>
            </HashLink>
          </div>
        )}
        
      </div>
    </>
  );
}
