import React from "react";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function CookieDialog() {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setOpen(false);
  };

  const handleDeny = () => {
    localStorage.setItem("cookieConsent", "denied");
    setOpen(false);
  };

  return (
    <Dialog open={open} size="sm" className="p-2 md:p-4">
      <div style={{ backgroundImage: 'url(/img/cookies/cookie.png)',
        backgroundSize: '250px',
        margin: '0 -110px',
        overflow: 'hidden',
        backgroundRepeat: 'space',
        position: 'absolute',
        zIndex: '100000000',
        width: '250px',
        top: '-68px',
        right: '90px'}}>
        <img src="/img/cookies/cookie.png" alt="cookies" />
      </div>
      {/* <div className="flex h-40 justify-center m-4">
        <img src="/img/cookies/cookie.png" alt="cookies" />
      </div> */}
      <DialogHeader className="flex justify-center text-4xl font-bold mt-[30px]">
        Kakor
      </DialogHeader>
      <DialogBody className="text-lg text-gray-900">
        <p>
          Vi använder cookies för att förbättra din upplevelse, visa personligt
          anpassade annonser eller innehåll, och analysera vår trafik. Genom att
          klicka på "Acceptera" samtycker du till vår användning av cookies.
        </p>
        <p className="mt-4">
          För mer information, vänligen läs vår integritetspolicy sida.
        </p>
      </DialogBody>
      <DialogFooter className="gap-4 flex flex-col-reverse">
        <button
          className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-3 rounded-lg text-dogblue hover:underline focus:ring focus:ring-gray-300 active:opacity-[0.85] block w-full text-md mr-1 items-center justify-center px-0 sm:mr-3 lg:flex lg:flex-1"
          type="button"
          style={{ position: 'relative', overflow: 'hidden'}}
          onClick={handleAccept}
        >
          Anpassa vilka kakor som sparas
        </button>
        <button
          className="text-md mr-1 block w-full select-none items-center justify-center rounded-lg bg-dogblue hover:bg-black px-0 py-3 text-center align-middle font-sans font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none sm:mr-3 lg:flex lg:flex-1"
          type="button"
          style={{ position: 'relative', overflow: 'hidden'}}
          onClick={handleAccept}
        >
          Godkänn alla kakor
        </button>
      </DialogFooter>
    </Dialog>
  );
}

export default CookieDialog;
