import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Textarea,
} from "@material-tailwind/react";
import Markdown from 'react-markdown';
import "./EditItem.css";

function convertToFileName(str) {
  const cleanStr = str.replace(/[^\w\säöå]/gi, "");// Remove special characters except spaces
  const trimmedStr = cleanStr.replace(/\s+/g, " ");// Replace multiple spaces with one space
  const fileName = trimmedStr.replace(/\s/g, "-");// Replace spaces with dashes
  return fileName.toLowerCase();
}

const EditItem = ({ data, pricing, pricing_ver }) => {
  const [open, setOpen] = React.useState(2);
  const [itemVerification, setItemVerification] = useState(null);
  const [itemPricing, setItemPricing] = useState(null);

  const [formData, setFormData] = useState({
    email: data.metadata.email || "",
    phone: data.metadata.phone || "",
    website: data.metadata.website || "",
    address: data.metadata.address || "",
    plusCode: data.metadata.plusCode || "",
    totalScore: data.metadata.totalScore || "",
  });

  const [formPricingData, setFormPricingData] = useState({
    fullprice: data.pricing?.fullprice || pricing.fullprice || 0,
    priceDependOnDogSize: data.pricing?.priceDependOnDogSize || pricing.priceDependOnDogSize || false,
    smallSizePrice: data.pricing?.smallSizePrice || pricing.smallSizePrice || 0,
    mediumSizePrice: data.pricing?.mediumSizePrice || pricing.mediumSizePrice || 0,
    largeSizePrice: data.pricing?.largeSizePrice || pricing.largeSizePrice || 0,
    smallSizeMeasurement: data.pricing?.smallSizeMeasurement || pricing.smallSizeMeasurement || "",
    mediumSizeMeasurement: data.pricing?.mediumSizeMeasurement || pricing.mediumSizeMeasurement || "",
    largeSizeMeasurement: data.pricing?.largeSizeMeasurement || pricing.largeSizeMeasurement || "",
  });

  const [typicalDay, setTypicalDay] = useState(data.typicalDay || []);
  const [typicalDayJson, setTypicalDayJson] = useState("");

  const [services, setServices] = useState(data.services || {
    hunddagis: false,
    kurser: false,
    behandling: false,
    veterinär: false,
    butik: false,
    rehabilitering: false,
    hundhotell: false,
    deltid: false,
    other: [],
  });
  const [servicesJson, setServicesJson] = useState("");

  const [staff, setStaff] = useState(data.staff || []);
  const [staffJson, setStaffJson] = useState("");

  const [about, setAbout] = useState(data.about || { introParagraphs: [] });
  const [aboutJson, setAboutJson] = useState("");

  const [gallery, setGallery] = useState(data.gallery || []);
  const [galleryJson, setGalleryJson] = useState("");

  const [video, setVideo] = useState(data.video || "");
  const [videoJson, setVideoJson] = useState("");

  const [instagram, setInstagram] = useState(data.socialMedia.instagram || "");
  const [facebook, setFacebook] = useState(data.socialMedia.facebook || "");

  useEffect(() => {
    let result = {
      pricing: null,
      pricing_ver: null,
    };
    pricing.forEach((pElement) => {
      if (pElement.centerName == data.centerName) {
        result.pricing = pElement;
      }
    });
    pricing_ver.forEach((pverElement) => {
      if (pverElement.centerName == data.centerName) {
        result.pricing_ver = pverElement;
      }
    });
    console.log("result", result);
    if(result.pricing_ver){
      setItemVerification(result.pricing_ver.verification);
    }
    if(result.pricing){
      setItemPricing(result.pricing);
    }
  }, [data, pricing, pricing_ver]);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const handleChangeMetadata = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangePricing = (e) => {
    let { name, value } = e.target;
    // if value is of type number parse a string to number
    if (typeof value === "string" && !isNaN(value)) {
      value = parseFloat(value);
    }
    setFormPricingData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeTypicalDay = (index, e) => {
    const { name, value } = e.target;
    const newTypicalDay = [...typicalDay];
    newTypicalDay[index] = { ...newTypicalDay[index], [name]: value };
    setTypicalDay(newTypicalDay);
  };

  const handleChangeTypicalDayJson = (e) => {
    setTypicalDayJson(e.target.value);
  };

  const handleChangeServices = (e) => {
    const { name, value, type, checked } = e.target;
    setServices((prevServices) => ({
      ...prevServices,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleChangeServicesJson = (e) => {
    setServicesJson(e.target.value);
  };

  const handleChangeStaff = (index, e) => {
    const { name, value } = e.target;
    const newStaff = [...staff];
    newStaff[index] = { ...newStaff[index], [name]: value };
    setStaff(newStaff);
  };

  const handleChangeStaffJson = (e) => {
    setStaffJson(e.target.value);
  };

  const handleChangeAbout = (index, e) => {
    const { value } = e.target;
    const newAbout = { ...about };
    newAbout.introParagraphs[index] = value;
    setAbout(newAbout);
  };

  const handleChangeAboutJson = (e) => {
    setAboutJson(e.target.value);
  };

  const handleChangeGallery = (index, e) => {
    const { value } = e.target;
    const newGallery = [...gallery];
    newGallery[index] = value;
    setGallery(newGallery);
  };

  const handleChangeGalleryJson = (e) => {
    setGalleryJson(e.target.value);
  };

  const handleChangeVideo = (e) => {
    const { value } = e.target;
    setVideo(value);
  };

  const handleChangeVideoJson = (e) => {
    setVideoJson(e.target.value);
  };

  const handleChangeSocialMediaJson = (e) => {
    setSocialMediaJson(e.target.value);
  };

  const addTypicalDayEntry = () => {
    setTypicalDay([...typicalDay, { time: "", description: "" }]);
  };

  const addStaffEntry = () => {
    setStaff([...staff, { name: "", surname: "", role: "", pic: "" }]);
  };

  const addAboutEntry = () => {
    setAbout({ ...about, introParagraphs: [...about.introParagraphs, ""] });
  };

  const addGalleryEntry = () => {
    setGallery([...gallery, ""]);
  };

  const handleChangeInstagram = (e) => {
    const { value } = e.target;
    setInstagram(value);
  };

  const handleChangeFacebook = (e) => {
    const { value } = e.target;
    setFacebook(value);
  };

  const handleSave = async () => {
    let newData = { ...data };
    newData.metadata = { ...data.metadata, ...formData }; // updating metadata
    newData.pricing = { ...data.pricing, ...formPricingData }; // updating pricing

    if (typicalDayJson) {
      try {
        newData.typicalDay = JSON.parse(typicalDayJson);
      } catch (error) {
        console.error("Invalid JSON format for Typical Day:", error);
        return; // Stop the save process if JSON is invalid
      }
    } else if(typicalDay.length > 0) {
      newData.typicalDay = typicalDay; // updating typicalDay
    }

    if (servicesJson) {
      try {
        newData.services = JSON.parse(servicesJson);
      } catch (error) {
        console.error("Invalid JSON format for Services:", error);
        return; // Stop the save process if JSON is invalid
      }
    } else if(services.hunddagis) {
      newData.services = services; // updating services
    }

    if (staffJson) {
      try {
        newData.staff = JSON.parse(staffJson);
      } catch (error) {
        console.error("Invalid JSON format for Staff:", error);
        return; // Stop the save process if JSON is invalid
      }
    } else if(staff.length > 0) {
      newData.staff = staff; // updating staff
    }

    if (aboutJson) {
      try {
        newData.about = JSON.parse(aboutJson);
      } catch (error) {
        console.error("Invalid JSON format for About:", error);
        return; // Stop the save process if JSON is invalid
      }
    } else if(about.introParagraphs.length > 0) {
      newData.about = about; // updating about
    }

    if (galleryJson) {
      try {
        newData.gallery = JSON.parse(galleryJson);
      } catch (error) {
        console.error("Invalid JSON format for Gallery:", error);
        return; // Stop the save process if JSON is invalid
      }
    } else if(gallery.length > 0) {
      newData.gallery = gallery; // updating gallery
    }

    if (videoJson) {
      try {
        newData.video = JSON.parse(videoJson);
      } catch (error) {
        console.error("Invalid JSON format for Video:", error);
        return; // Stop the save process if JSON is invalid
      }
    } else if(video !== "") {
      newData.video = video; // updating video
    }

    if(instagram !== "") {
      newData.socialMedia = { "instagram": instagram }; // updating social media
    }
    if(facebook !== "") {
      newData.socialMedia = { "facebook": facebook }; // updating social media
    }

    // Log newData to console to check the updated data
    console.log("Updated data to be saved:", newData);

    try {
      const response = await fetch("http://localhost:3001/api/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = await response.json();
      console.log("Successfully updated the center data:", result);
      // Optionally: Provide user feedback about the successful save
    } catch (error) {
      console.error("Failed to update the center data:", error);
      // Optionally: Provide user feedback about the failure
    }
  };

  return (
    <div className="edit-item-container">
      <DialogHeader className="!pb-0">{data.centerName} <span className="ml-2"><a href={`https://${data.metadata.website}`} target="_blank">{data.metadata.website}</a></span></DialogHeader>
      <DialogBody>

        {/* Metadata */}
        <Accordion open={open === 1} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(1)} className="!border-b-0 !py-2">
            Metadata
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            <div className="flex flex-wrap">
              {[
                "email",
                "phone",
                "website",
                "address",
                "plusCode",
                "totalScore",
              ].map((field) => (
                <div key={field} className="mb-4 mr-2 w-72">
                  <Input
                    label={field}
                    name={field}
                    value={formData[field]}
                    onChange={handleChangeMetadata}
                    error={!formData[field]}
                  />
                </div>
              ))}
            </div>
          </AccordionBody>
        </Accordion>

        {/* Pricing */}
        <Accordion open={open === 2} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(2)} className="!border-b-0 !py-2">
            Pricing
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            {/* Pricing form */}
            <div className="flex flex-wrap">
              {[
                "fullprice",
                "priceDependOnDogSize",
                "smallSizePrice",
                "mediumSizePrice",
                "largeSizePrice",
                "smallSizeMeasurement",
                "mediumSizeMeasurement",
                "largeSizeMeasurement",
              ].map((field) => (
                <div key={field} className="mb-4 mr-2 w-72">
                  <Input
                    label={field}
                    name={field}
                    value={formPricingData[field]}
                    onChange={handleChangePricing}
                    error={!formPricingData[field]}
                  />
                </div>
              ))}
            </div>

            {/* Pricing verification */}
            <div className="flex flex-row">
              <div className="flex flex-col">
                <div className="font-bold text-xl">JSON from data</div>
                {[
                  "fullprice",
                  "priceDependOnDogSize",
                  "smallSizePrice",
                  "mediumSizePrice",
                  "largeSizePrice",
                  "smallSizeMeasurement",
                  "mediumSizeMeasurement",
                  "largeSizeMeasurement",
                ].map((field) => (
                  <div className="mr-2">
                    <span>
                      <span>{field}</span>:
                      <span className="ml-1 text-purple-500">{typeof formPricingData[field] === "boolean" ? (formPricingData[field] ? "true" : "false") : formPricingData[field]}</span>
                    </span>
                  </div>
                ))}
              </div>

              <div className="flex flex-col">
                <div className="font-bold text-xl">JSON from pricing</div>
                {[
                  "fullprice",
                  "priceDependOnDogSize",
                  "smallSizePrice",
                  "mediumSizePrice",
                  "largeSizePrice",
                  "smallSizeMeasurement",
                  "mediumSizeMeasurement",
                  "largeSizeMeasurement",
                ].map((field) => (
                  <div className="mr-2">
                    {itemPricing && (
                      <span>
                        <span>{field}</span>:
                        <span className="ml-1 text-purple-500">{typeof itemPricing[field] === "boolean" ? (itemPricing[field] ? "true" : "false") : itemPricing[field]}</span>
                      </span>
                    )}
                  </div>
                ))}
              </div>

              <div className="overflow-scroll h-[400px]">
                {itemVerification ? <Markdown>{itemVerification}</Markdown> : <span>No verification data</span>}
              </div>

            </div>
          </AccordionBody>
        </Accordion>

        {/* Services */}
        <Accordion open={open === 4} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(4)} className="!border-b-0 !py-2">
            Services
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            <div className="flex flex-wrap mb-4">
              {Object.keys(services).map((key) => (
                <div key={key} className="mr-2 w-72">
                  <Input
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    name={key}
                    type={typeof services[key] === "boolean" ? "checkbox" : "text"}
                    checked={typeof services[key] === "boolean" ? services[key] : undefined}
                    value={typeof services[key] === "boolean" ? undefined : services[key]}
                    onChange={handleChangeServices}
                    error={!services[key]}
                  />
                </div>
              ))}
            </div>
            <div className="mt-4">
              <Textarea
                label="Services JSON Input"
                value={servicesJson}
                onChange={handleChangeServicesJson}
              />
            </div>
          </AccordionBody>
        </Accordion>


        {/* About */}
        <Accordion open={open === 6} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(6)} className="!border-b-0 !py-2">
            About
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            {about.introParagraphs.map((paragraph, index) => (
              <div key={index} className="mr-2 w-full mb-4">
                <Textarea
                  label={`Paragraph ${index + 1}`}
                  value={paragraph}
                  onChange={(e) => handleChangeAbout(index, e)}
                />
              </div>
            ))}
            <Button variant="gradient" color="blue" onClick={addAboutEntry}>
              <span>Add Paragraph</span>
            </Button>
            <div className="mt-4">
              <Textarea
                label="About JSON Input"
                value={aboutJson}
                onChange={handleChangeAboutJson}
              />
            </div>
          </AccordionBody>
        </Accordion>


        {/* Typical Day */}
        <Accordion open={open === 3} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(3)} className="!border-b-0 !py-2">
            Typical Day
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            {typicalDay.map((entry, index) => (
              <div key={index} className="flex flex-wrap mb-4">
                <div className="mr-2 w-72">
                  <Input
                    label="Time"
                    name="time"
                    value={entry.time}
                    onChange={(e) => handleChangeTypicalDay(index, e)}
                  />
                </div>
                <div className="mr-2 w-72">
                  <Input
                    label="Description"
                    name="description"
                    value={entry.description}
                    onChange={(e) => handleChangeTypicalDay(index, e)}
                  />
                </div>
              </div>
            ))}
            <Button variant="gradient" color="blue" onClick={addTypicalDayEntry}>
              <span>Add Entry</span>
            </Button>
            <div className="mt-4">
              <Textarea
                label="Typical Day JSON Input"
                value={typicalDayJson}
                onChange={handleChangeTypicalDayJson}
              />
            </div>
          </AccordionBody>
        </Accordion>

        {/* Staff */}
        <Accordion open={open === 5} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(5)} className="!border-b-0 !py-2">
            Staff
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            {staff.map((member, index) => (
              <div key={index} className="flex flex-wrap mb-4">
                {Object.keys(member).map((key) => (
                  <div key={key} className="mr-2 w-72">
                    <Input
                      label={key.charAt(0).toUpperCase() + key.slice(1)}
                      name={key}
                      value={member[key]}
                      onChange={(e) => handleChangeStaff(index, e)}
                    />
                  </div>
                ))}
              </div>
            ))}
            <Button variant="gradient" color="blue" onClick={addStaffEntry}>
              <span>Add Staff Member</span>
            </Button>
            <div className="mt-4">
              <Textarea
                label="Staff JSON Input"
                value={staffJson}
                onChange={handleChangeStaffJson}
              />
            </div>
          </AccordionBody>
        </Accordion>

        {/* Gallery */}
        <Accordion open={open === 7} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(7)} className="!border-b-0 !py-2">
            Gallery
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            {gallery.map((url, index) => (
              <div key={index} className="mr-2 w-full mb-4">
                <Input
                  label={`Image URL ${index + 1}`}
                  value={url}
                  onChange={(e) => handleChangeGallery(index, e)}
                />
              </div>
            ))}
            <Button variant="gradient" color="blue" onClick={addGalleryEntry}>
              <span>Add Image URL</span>
            </Button>
            <div className="mt-4">
              <Textarea
                label="Gallery JSON Input"
                value={galleryJson}
                onChange={handleChangeGalleryJson}
              />
            </div>
          </AccordionBody>
        </Accordion>

        {/* Video */}
        <Accordion open={open === 8} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(8)} className="!border-b-0 !py-2">
            Video
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            <div className="mr-2 w-full mb-4">
              <Input
                label="Video URL"
                value={video}
                onChange={handleChangeVideo}
              />
            </div>
            <div className="mt-4">
              <Textarea
                label="Video JSON Input"
                value={videoJson}
                onChange={handleChangeVideoJson}
              />
            </div>
          </AccordionBody>
        </Accordion>

        {/* Social Media */}
        <Accordion open={open === 9} className="mb-2 rounded-lg border border-blue-gray-100 px-4">
          <AccordionHeader onClick={() => handleOpen(9)} className="!border-b-0 !py-2">
            Social Media
          </AccordionHeader>
          <AccordionBody className="text-base font-normal !py-2">
            <div className="mr-2 w-full mb-4 space-y-4">
              <Input
                label="Instragram Input"
                value={instagram}
                onChange={handleChangeInstagram}
              />
              <Input
                label="Facebook Input"
                value={facebook}
                onChange={handleChangeFacebook}
              />
            </div>
          </AccordionBody>
        </Accordion>
      </DialogBody>
      <DialogFooter>
        <Button variant="gradient" color="green" onClick={handleSave}>
          <span className="text-[16px]">Update Database</span>
        </Button>
      </DialogFooter>
    </div>
  );
};

export default EditItem;
