import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";

import { collection, getDocs, getDocsFromCache } from 'firebase/firestore';
import { db } from './db/firebase.js';

import ReactDOM from "react-dom/client";
import "./index.css";
import ListMap from "./views/ListMap.jsx";
import Admin from "./views/admin/Admin.jsx";
import Details from "./views/details/Details.jsx";
import NotFound from "./views/notFound/NotFound.jsx";

import AboutUs from "./views/pages/AboutUs.jsx";
import Terms from "./views/pages/Terms.jsx";
import PrivacyPolicy from "./views/pages/PrivacyPolicy.jsx";
import ContactPage from "./views/pages/ContactPage.jsx";
import FAQPage from "./views/pages/FAQPage.jsx";
import BlogTemplate from "./views/pages/BlogTemplate.jsx";

async function rootLoader({ params }) {
  /// NOT BEING USED

  // const PRICING_URL = "http://localhost:3001/api/pricing";
  // const response_pricing = await fetch(PRICING_URL);
  // let pricing = [];
  // pricing = await response_pricing.json();
  // console.log(pricing);

  // const PRICING_VERIFICATION_URL = "http://localhost:3001/api/verification_pricing";
  // const response_pricing_verification = await fetch(PRICING_VERIFICATION_URL);
  // let pricing_ver = [];
  // pricing_ver = await response_pricing_verification.json();
  // console.log(pricing_ver);





  // ------- Fetch data locally ------- 
  // const API_URL = "http://localhost:3001/api/data";
  // const response = await fetch(API_URL);
  // let data = [];
  // data = await response.json();
  // console.log(data);

  // ------- Fetch data from firebase and cache ------- 
  const collectionName = "hunddagis";
  const fetchData = async () => {
    try {
      const collectionRef = collection(db, collectionName); // Replace with your collection name
      let querySnapshot;

      // try {
      //   querySnapshot = await getDocsFromCache(collectionRef);
      //   if (querySnapshot.empty) {
      //     console.warn("Cache is empty, fetching from server.");
      //     throw new Error("Cache is empty");
      //   }
      // } catch (cacheError) {
      //   console.warn("Error fetching from cache:", cacheError);
      //   querySnapshot = await getDocs(collectionRef);
      // }
      querySnapshot = await getDocs(collectionRef);

      const items = [];
      querySnapshot.forEach((doc) => {
        items.push({ id: doc.id, ...doc.data() });
      });

      return items;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  let data = await fetchData();

  
  
  
  
  // console.log(data);
  const { contactId } = params;
  if (contactId) {
    const result = data.find(item => {
      return item.id === contactId;
      // return item.id === parseInt(contactId, 10);
    });
    return result || {};
  }
  // return {data: data};
  return {data: data};
}


const router = createBrowserRouter([
  {
    path: "/",
    element: <ListMap />,
    errorElement: <NotFound />,
    loader: rootLoader,
  },
  {
    path: "hunddagis/:contactId",
    element: <Details />,
    errorElement: <NotFound message="Hunddagis hittades inte" />,
    loader: rootLoader,
  },
  {
    path: "om-oss",
    element: <AboutUs />,
    loader: rootLoader,
  },
  {
    path: "villkor",
    element: <Terms />,
    loader: rootLoader,
  },
  {
    path: "integritetspolicy",
    element: <PrivacyPolicy />,
    loader: rootLoader,
  },
  {
    path: "kontakt",
    element: <ContactPage />,
    loader: rootLoader,
  },
  {
    path: "faq",
    element: <FAQPage />,
    loader: rootLoader,
  },
  {
    path: "blog",
    element: <BlogTemplate />,
    loader: rootLoader,
  },
  {
    path: "admin",
    element: window.location.hostname === "localhost" ? <Admin /> : <Navigate to="/" />,
    loader: rootLoader,
  },
  {
    path: "*",
    element: <NotFound />,
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <RouterProvider router={router} />,
  // <React.StrictMode>
  //   <RouterProvider router={router} />
  // </React.StrictMode>,
);
