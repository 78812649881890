import React from "react";
import NavMenu from "../../blocks/NavMenu/NavMenu.jsx";
import Footer from "../../blocks/Footer.jsx";

import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
} from "@material-tailwind/react";

const BlogTemplate = () => {
  const parksData = [
    {
      location: "Östermalm, central Stockholm",
      name: "1. Humlegården",
      facilities: "Large off-leash area, benches, water fountains",
      best_for: "Central location, socializing with other dogs, clean and safe.",
      link: "https://parker.stockholm/parker/humlegarden/",
      img: "https://www.city-guide-stockholm.com/_bibli/annonces/413/hd/humlegarden-01.jpg?v=ficheAnnonce&width=1208&height=540&pixelRatio=2.0000",
      description: "Humlegården is one of Stockholm’s oldest and most popular parks, located in the heart of the city. The dog area is quite spacious, allowing dogs to run freely off-leash. There are usually plenty of other dogs, so it’s a great spot for socialization. The park is well-maintained, and there are benches for owners to relax while their dogs play."
    },
    {
      location: "Kungsholmen",
      name: "2. Rålambshovsparken",
      facilities: "Large off-leash area, near a waterfront",
      best_for: "Dogs who love running, dog-owner socializing",
      link: "https://parker.stockholm/parker/ralambshovsparken/",
      img: "https://parker.stockholm/optimized/c2x1_xxlarge/siteassets/parker-och-natur/parker/ralambshovsparken/ralambshovsparken.jpg",
      description: "Located by the waterfront on Kungsholmen, Rålambshovsparken is perfect for both dogs and their owners. The park offers a large off-leash area where dogs can run freely and enjoy the green space. In summer, it’s a popular spot for picnics and social gatherings, making it an enjoyable experience for owners too. There’s even a nearby café that allows dogs."
    },
    {
      location: "Södermalm",
      name: "3. Tantolunden",
      facilities: "Off-leash area, hilly terrain, water access",
      best_for: "Adventurous dogs who love varied terrain.",
      link: "https://parker.stockholm/parker/tantolunden/",
      img: "https://parker.stockholm/optimized/c2x1_xxlarge/siteassets/parker-och-natur/parker/tantolunden/tantolunden.jpg",
      description: "Tantolunden, located in Södermalm, is a park known for its beautiful views and diverse landscapes. The dog area here is off-leash and offers hills, flat spaces, and even access to water, making it ideal for energetic dogs. The park is spacious and allows dogs to get plenty of exercise. Owners will also appreciate the scenic walks through the surrounding areas."
    },
    {
      location: "Östermalm",
      name: "4. Djurgården",
      facilities: "Vast open areas, scenic routes, waterfront trails",
      best_for: "Long walks, off-leash exploration, nature-loving dogs.",
      link: "https://www.kungligaslotten.se/vara-besoksmal/kungliga-djurgarden.html",
      img: "https://www.kungligaslotten.se/images/18.3ced9ede1817ff2e17c1cf40/1725475804599/djurgarden_bl%C3%A5-porten-foto-Jonas-Borg.webp",
      description: "Djurgården is part of the Royal National City Park and offers extensive trails and fields for dog owners and their pets. While parts of Djurgården allow dogs off-leash, it’s best suited for those who enjoy long, scenic walks with their dogs. There are plenty of dog-friendly cafés and eateries along the way, making it a perfect spot for a full day out."
    },
    {
      location: "Långholmen Island, near Södermalm",
      name: "5. Långholmen",
      facilities: "Off-leash area, beach access, forest trails",
      best_for: "Water-loving dogs, island exploration.",
      link: "https://parker.stockholm/parker/langholmen/",
      img: "https://www.city-guide-stockholm.com/_bibli/annonces/414/hd/langholmen-01.jpg?v=ficheAnnonce&width=1208&height=540&pixelRatio=2.0000",
      description: "Located on the small island of Långholmen, this park is a hidden gem for dog owners. The dog area is off-leash and offers access to a dog-friendly beach. If your dog loves swimming, Långholmen is perfect. The park also has wooded trails for those who prefer a peaceful nature walk. The island itself is accessible via bridges from Södermalm."
    },
  ];

{/* 

<section className="mt-12">
  <h2 className="text-3xl font-bold mb-2">6. Vasaparken</h2>
  <p className="mb-2"><strong>Location:</strong> Norrmalm</p>
  <p className="mb-2"><strong>Facilities:</strong> Off-leash dog park, urban setting</p>
  <p className="mb-4"><strong>Best For:</strong> Quick urban dog play sessions, socializing with locals.</p>
  <p>
    Vasaparken, situated in a central location, is a convenient choice for city-dwellers who want to take their dogs out for a quick run or play session. The dog park is fenced, and there’s a designated off-leash area, making it safe for dogs to roam around freely. It’s a well-frequented spot, so your dog will have plenty of opportunities to meet others.
  </p>
</section>

<section className="mt-12">
  <h2 className="text-3xl font-bold mb-2">7. Norra Djurgårdsstaden Hundrastgård</h2>
  <p className="mb-2"><strong>Location:</strong> Norra Djurgården</p>
  <p className="mb-2"><strong>Facilities:</strong> Fenced off-leash area, well-maintained grounds</p>
  <p className="mb-4"><strong>Best For:</strong> Secure off-leash play.</p>
  <p>
    This is a smaller, fenced-off area located near Norra Djurgården, perfect for dog owners looking for a safe and secure place to let their dogs off-leash. The park is well-maintained and is frequented by locals, making it a good spot for casual playtime and meeting other dogs.
  </p>
</section>

<section className="mt-12">
  <h2 className="text-3xl font-bold mb-2">8. Gärdet</h2>
  <p className="mb-2"><strong>Location:</strong> Östermalm</p>
  <p className="mb-2"><strong>Facilities:</strong> Large open fields, partial off-leash areas</p>
  <p className="mb-4"><strong>Best For:</strong> Dogs that love to run, energetic play.</p>
  <p>
    Gärdet, an expansive field near Östermalm, is ideal for dogs that need a lot of space to run. While not fully off-leash, there are certain areas where dogs are allowed more freedom. The wide open spaces make it a great spot for active dogs who love to burn off energy.
  </p>
</section>

<section className="mt-12">
  <h2 className="text-3xl font-bold mb-2">9. Skinnarviksparken</h2>
  <p className="mb-2"><strong>Location:</strong> Södermalm</p>
  <p className="mb-2"><strong>Facilities:</strong> Off-leash area, views of the city</p>
  <p className="mb-4"><strong>Best For:</strong> Urban playtime with a view.</p>
  <p>
    Located in Södermalm, Skinnarviksparken offers a nice balance of urban and nature settings. The park has an off-leash dog area where your pet can run around freely while you take in stunning views of Stockholm’s skyline. It’s a more peaceful park, often less crowded than others on this list.
  </p>
</section>

<section className="mt-12">
  <h2 className="text-3xl font-bold mb-2">10. Nytorpsgärde</h2>
  <p className="mb-2"><strong>Location:</strong> Johanneshov</p>
  <p className="mb-2"><strong>Facilities:</strong> Large off-leash area, open fields</p>
  <p className="mb-4"><strong>Best For:</strong> Dogs that enjoy large, open spaces.</p>
  <p>
    Nytorpsgärde, located in the southern part of the city, offers a large off-leash area with plenty of space for dogs to run and explore. The flat terrain makes it ideal for playing fetch, and there’s ample room for dogs to stretch their legs without any restrictions.
  </p>
</section> */}

  return (
    <div>
      <div className="z-10 w-full bg-white">
        <NavMenu></NavMenu>
      </div>

      <div className="min-h-screen bg-white pb-20 text-gray-800">
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="relative flex h-full justify-center md:justify-start">
            <div className="mt-4 w-full max-w-[860px] sm:mt-10 md:max-w-full lg:mt-12">
              <main class="flex-auto">
                <div class="mt-16 sm:px-8 lg:mt-32">
                  <div class="mx-auto w-full max-w-7xl">
                    <div class="">
                      <div class="mx-auto max-w-2xl lg:max-w-5xl">
                        <div class="xl:relative">
                          <div class="mx-auto max-w-2xl text-lg">


                            <article className="max-w-4xl mx-auto px-4 py-6 text-gray-800">
                              <header class="flex flex-col">
                                <h1 class="mt-6 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-5xl">
                                  Top 10 Best Dog Parks in Stockholm for Off-Leash Fun
                                </h1>
                                <time datetime="2024-10-09" class="order-first flex items-center text-base text-gray-500 dark:text-gray-500">
                                  <span class="">October 9, 2024</span>
                                </time>
                              </header>
                              <div
                                class="prose dark:prose-invert mt-8"
                                data-mdx-content="true"
                              >
                                

                              <p className="mb-4">
                                Stockholm is not only a city known for its stunning natural beauty but also for being a pet-friendly destination. If you’re a dog owner in the city, there are plenty of parks and open spaces designed for your dog to play, socialize, and exercise safely. Whether you’re looking for off-leash areas, large open fields, or scenic walks, Stockholm has several great options.
                              </p>
                              <p className="mb-8">
                                Here are the top 10 best dog parks in Stockholm, where your dog can enjoy some well-deserved fun:
                              </p>

                              {parksData.map((park, index) => (
                                <section key={index}>
                                  <Card className="w-full max-w-[48rem] flex-row">
                                    <CardHeader
                                      shadow={false}
                                      floated={false}
                                      className="m-0 w-2/5 shrink-0 rounded-r-none"
                                    >
                                      <img
                                        src={park.img}
                                        alt="card-image"
                                        className="h-full w-full object-cover"
                                      />
                                    </CardHeader>
                                    <CardBody>
                                      <Typography variant="h6" color="gray" className="mb-4 uppercase">
                                        {park.location}
                                      </Typography>
                                      <Typography variant="h4" color="blue-gray" className="mb-2">
                                        {park.name}
                                      </Typography>
                                      <Typography color="black" className="mb-8 font-normal">
                                        <p className="mb-2"><strong>Facilities:</strong> {park.facilities}</p>
                                        <p className="mb-4"><strong>Best For:</strong> {park.best_for}</p>
                                        <a href={park.url} target="_blank" className="text-dogblue hover:underline">Link to website</a>
                                      </Typography>
                                    </CardBody>
                                  </Card>

                                  <p className="mt-4 mb-12">
                                    {park.description}
                                  </p>
                                </section>
                              ))}



                              

                              <h2 className="text-2xl font-bold mt-6">Tips for Dog Owners Visiting Stockholm’s Parks</h2>
                              <ul className="list-disc list-inside mb-4">
                                <li>Always clean up after your dog—most parks have dog waste bags available, but it’s best to bring your own.</li>
                                <li>Make sure your dog is well-socialized, especially in busy parks like Humlegården and Vasaparken.</li>
                                <li>Keep an eye on park rules as some areas only allow off-leash dogs during certain times.</li>
                                <li>Bring water for your dog, especially during warmer months, and ensure they’re well-hydrated after playtime.</li>
                              </ul>

                              <h2 className="text-2xl font-bold mt-6">Conclusion</h2>
                              <p>
                                Stockholm is a dog-friendly city with numerous parks that offer great opportunities for dogs to run, play, and socialize. Whether you're looking for a quiet walk in Djurgården or an energetic run in Gärdet, there’s something for every dog and owner. The city’s parks are well-maintained and welcoming, so you and your furry friend will always have a fantastic place to visit.
                              </p>


                              </div>
                            </article>




                            <article>
                              <header class="flex flex-col">
                                <h1 class="mt-6 text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-5xl">
                                  Crafting a design system for a multiplanetary
                                  future
                                </h1>
                                <time
                                  datetime="2022-09-05"
                                  class="order-first flex items-center text-base text-gray-500 dark:text-gray-500"
                                >
                                  <span class="">September 5, 2022</span>
                                </time>
                              </header>
                              <div
                                class="prose dark:prose-invert mt-8"
                                data-mdx-content="true"
                              >
                                <p className="py-6">
                                  Most companies try to stay ahead of the curve
                                  when it comes to visual design, but for
                                  Planetaria we needed to create a brand that
                                  would still inspire us 100 years from now when
                                  humanity has spread across our entire solar
                                  system.
                                </p>
                                <img
                                  alt=""
                                  loading="lazy"
                                  width="1310"
                                  height="872"
                                  decoding="async"
                                  data-nimg="1"
                                  className="rounded-2xl"
                                  // style="color:transparent"
                                  src="https://spotlight.tailwindui.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fplanetaria-design-system.d4cfce90.png&w=3840&q=75"
                                />
                                <p className="py-6">
                                  I knew that to get it right I was going to
                                  have to replicate the viewing conditions of
                                  someone from the future, so I grabbed my space
                                  helmet from the closet, created a new Figma
                                  document, and got to work.
                                </p>
                                <h2 className="mt-12 text-2xl font-bold">
                                  Sermone fata
                                </h2>
                                <p className="py-6">
                                  Lorem markdownum, bracchia in redibam! Terque
                                  unda puppi nec, linguae posterior in utraque
                                  respicere candidus Mimasque formae; quae
                                  conantem cervice. Parcite variatus, redolentia
                                  adeunt. Tyrioque dies, naufraga sua adit
                                  partibus celanda torquere temptata, erit
                                  maneat et ramos, <a href="#">iam</a> ait
                                  dominari potitus! Tibi litora matremque
                                  fumantia condi radicibus opusque.
                                </p>
                                <figure class="border-l-2 border-gray-400 text-xl pl-6 my-8">
                                  <blockquote cite="#">
                                    <p class="italic font-bold text-gray-500">
                                      "And thank you for turning my personal
                                      jean jacket into a couture piece."
                                    </p>
                                  </blockquote>
                                  <figcaption>
                                    <p class="text-sm mt-2 text-gray-600">
                                      — Kanye West, Producer
                                    </p>
                                  </figcaption>
                                </figure>
                                <p className="py-6">
                                  Deus feram verumque, fecit, ira tamen, terras
                                  per alienae victum. Mutantur levitate quas ubi
                                  arcum ripas oculos abest. Adest{" "}
                                  <a href="#">commissaque victae</a> in gemitus
                                  nectareis ire diva dotibus ora, et findi huic
                                  invenit; fatis? Fractaque dare superinposita
                                  nimiumque simulatoremque sanguine, at voce
                                  aestibus diu! Quid veterum hausit tu nil
                                  utinam paternos ima, commentaque.
                                </p>

                                <p className="py-6">
                                  Aere repetiti cognataque natus. Habebat vela
                                  solutis saepe munus nondum adhuc oscula nomina
                                  pignora corpus deserat.
                                </p>
                                <h2>
                                  Lethaei Pindumve me quae dinumerat Pavor
                                </h2>
                                <p className="py-6">
                                  Idem se saxa fata pollentibus geminos; quos
                                  pedibus. Est urnis Herses omnes nec divite: et
                                  ille illa furit sim verbis Cyllenius.
                                </p>
                                <ol>
                                  <li>Captus inpleverunt collo</li>
                                  <li>Nec nam placebant</li>
                                  <li>Siquos vulgus</li>
                                  <li>Dictis carissime fugae</li>
                                  <li>A tacitos nulla viginti</li>
                                </ol>
                                <p className="py-6">
                                  Ungues fistula annoso, ille addit linoque
                                  motatque uberior verso
                                  <a href="#">rubuerunt</a> confine desuetaque.{" "}
                                  <em>Sanguine</em> anteit emerguntque
                                  expugnacior est pennas iniqui ecce{" "}
                                  <strong>haeret</strong> genus: peiora imagine
                                  fossas Cephisos formosa! Refugitque amata{" "}
                                  <a href="#">refelli</a>
                                  supplex. Summa brevis vetuere tenebas, hostes
                                  vetantis, suppressit, arreptum regna. Postquam
                                  conpescit iuvenis habet corpus, et erratica,
                                  perdere, tot mota ars talis.
                                </p>

                                <p className="py-6">
                                  Sit volat naturam; motu Cancri. Erat pro simul
                                  quae valuit quoque timorem quam proelia: illo
                                  patrio <em>esse summus</em>, enim sua
                                  serpentibus, Hyleusque. Est coniuge recuso;
                                  refert Coroniden ignotos manat, adfectu.
                                </p>
                              </div>
                            </article>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>

            <div className="sticky top-[4rem] mb-20 ml-12 mt-[10rem] hidden h-max min-w-[250px] rounded-xl bg-transparent p-[1.5rem] shadow-none lg:block xl:ml-32">
              <form
                action="/thank-you"
                class="rounded-2xl border p-6 dark:border-gray-700/40"
              >
                <h2 class="flex text-sm font-semibold text-gray-900 dark:text-gray-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>

                  <span class="ml-3">Stay up to date</span>
                </h2>
                <p class="mt-2 text-sm text-gray-600 dark:text-gray-400">
                  Get notified when I publish something new, and unsubscribe at
                  any time.
                </p>
                <div class="mt-6 flex">
                  <input
                    placeholder="Email address"
                    aria-label="Email address"
                    required=""
                    class="min-w-0 flex-auto appearance-none rounded-md border border-gray-900/10 bg-white px-3 py-[calc(theme(spacing.2)-1px)] shadow-md shadow-gray-800/5 placeholder:text-gray-400 focus:border-teal-500 focus:outline-none focus:ring-4 focus:ring-teal-500/10 dark:border-gray-700 dark:bg-gray-700/[0.15] dark:text-gray-200 dark:placeholder:text-gray-500 dark:focus:border-teal-400 dark:focus:ring-teal-400/10 sm:text-sm"
                    type="email"
                  />
                  <button
                    class="ml-4 inline-flex flex-none items-center justify-center gap-2 rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-gray-100 outline-offset-2 transition hover:bg-gray-700 active:bg-gray-800 active:text-gray-100/70 active:transition-none dark:bg-gray-700 dark:hover:bg-gray-600 dark:active:bg-gray-700 dark:active:text-gray-100/70"
                    type="submit"
                  >
                    Join
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default BlogTemplate;
