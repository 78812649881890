import { Card } from "@material-tailwind/react";
import { CardBody } from "@material-tailwind/react";
import { CardFooter } from "@material-tailwind/react";
import FloatingDetails from "../blocks/FloatingDetails.jsx";
import Paw from "../assets/Paw.jsx";
import Separator from "../assets/Separator.jsx";
import TagList from "../components/TagList.jsx";
import { Link } from "react-router-dom";

export default function BookingCard({ data, isHighlighted, actions, linkTo }) {

  function parseSwedishAddress(address) {
    address = address.trim();
  
    // Split address into parts using commas and spaces
    const parts = address.split(", ");

    if (parts.length < 2) {
      return null; // Invalid address format
    }

    const street = parts[0];
    const parts2 = parts[1].split(" ");

    const postcode = parts2[0] + " " + parts2[1];
    const city = parts[1].replace(postcode, "").substring(1) || "";
  
    return {
      street,
      postcode,
      city
    };
  }

  const addressInfo = parseSwedishAddress(data.metadata.address);
  return (
    <Card className={`mb-5 w-full border-white rounded-xl border-2 bg-white ${isHighlighted ? "lg:border-indigo-300 shadow-xl" : ""}`}>

      {/* Over SM visible */}
      <Link to={linkTo} aria-label={`Läs mer om ${linkTo}`}>
        <div className={`flex rounded-t-lg flex-col items-start sm:flex-row sm:flex`}>
          <div className="flex flex-1 flex-row mt-3 sm:mt-6 mr-4 sm:mr-0 items-center">
            <div
              className={`ml-3 sm:ml-6 max-w-[75px] bg-clip-border mx-4 overflow-hidden relative rounded-xl`}
            >
              {/* <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " /> */}
              <img
                src={`${data.logo ? `/img/logos/${data.logo.name}.${data.logo.extension}` : "/img/logo-details.svg"}`}
                width="75"
                height="75"
                alt="hunddagis logo"
                style={!data.logo ? { filter: 'grayscale(1)'} : {}}
                />
            </div>
            <div className="flex flex-col flex-1">
              <h3 className={`text-2xl text-gray-900 font-bold`}
              style={{ fontSize: '1.52rem', lineHeight: '1.75rem' }}
              >
                {data.centerName}
              </h3>
              {/* <div className={`text-md text-gray-600`}>
                {data.metadata.address}
              </div> */}
              {addressInfo && (
                <div className={`text-lg text-gray-600 flex flex-row items-center`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-mb-1 -mt-1 h-5 w-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
                  </svg>
                  <span className="ml-1">{addressInfo.city}</span>
                </div>
              )}
            </div>
          </div>

          <div className="mx-4 mr-6 mt-3 sm:mt-6 flex items-baseline sm:items-end flex-row sm:flex-col">
            <div className="flex flex-row items-center">
              <Paw size={8} extraClasses={'mr-1 rotate-[30deg]'} />
              <div className={`text-4xl text-gray-900`}>{data.metadata.totalScore ? data.metadata.totalScore : '-'}</div>
            </div>
            <div className={`ml-2 sm:ml-0 text-md text-gray-600`}>{ data.reviews.length > 0 ? data.reviews.length + ' recensioner' : ''}</div>
          </div>
        </div>
      </Link>

      <CardBody className="p-3 sm:p-6">
        {data.services && (
          <TagList services={data.services} />
        )}

        <Separator size="sm" />

        {data.pricing && data.pricing.fullprice && (
          <FloatingDetails pricing={data.pricing} name={data.name} />
        )}
      </CardBody>
      <CardFooter className="p-3 sm:p-6 sm:pt-0">
        {actions}
      </CardFooter>
    </Card>
  );
}
