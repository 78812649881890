import React, { useEffect, useState } from 'react';
import NavMenu from "../../blocks/NavMenu/NavMenu.jsx";
import Footer from "../../blocks/Footer.jsx";
import Game from './lib';


const NotFound = React.memo(({ message = 'Sidan hittades inte' }) => {
  const [spacebarPressed, setSpacebarPressed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleSpacebar = (event) => {
      if (event.code === 'Space') {
        event.preventDefault(); // Prevent the default scroll action
        setSpacebarPressed(true);
      }
    };

    const handleTouch = () => {
      setSpacebarPressed(true);
    };

    window.addEventListener('keydown', handleSpacebar);
    document.addEventListener('touchstart', handleTouch);

    const userAgent = navigator.userAgent;
    if (userAgent.match(/Mobile|Android|iOS/i)) {
      setIsMobile(true);
    }

    return () => {
      window.removeEventListener('keydown', handleSpacebar);
      document.removeEventListener('touchstart', handleTouch);
    };
  }, []);

  return (
    <div>
      <div className="z-10 w-full bg-white">
        <NavMenu></NavMenu>
      </div>

      <div className="flex flex-col items-center justify-center h-screen bg-white text-center p-4" style={{ height: 'calc(100vh - 100px)'}}>
        <h1 className="text-4xl lg:text-5xl font-black mb-4 text-dogblue">Hoppsan! {message}</h1>
        <p className="mb-6 text-xl lg:text-2xl">Den här sidan verkar ha sprungit iväg!</p>
        <div className="w-full max-w-xl">
          <Game />
          <div className={`text-gray-500 -mt-36 mb-36 ${spacebarPressed ? 'text-white' : ''}`}>
            {isMobile ? 'Tryck för att börja spelet' : 'Tryck mellanslagstangenten för att börja spelet'}
          </div>
        </div>
        <p className="text-lg">
        Oroa dig inte, du kan gå tillbaka till {' '}
          <a href="/" className="text-dogblue hover:underline">hemmet</a> eller fortsätta att söka efter hunddagis.
        </p>
      </div>

      <Footer></Footer>
    </div>
  );
});

export default NotFound;
