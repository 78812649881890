function Separator ({ size }) {
  let classValues = ""
  if(size === "sm"){
    classValues = "my-3"
  }
  if(size === "lg"){
    classValues = "my-6 sm:my-11"
  }
  return (
    <div className={`${classValues} h-px rounded-lg bg-gray-300`}></div>
  );
}

export default Separator;