import React, { useState } from "react";
import NavMenu from "../../blocks/NavMenu/NavMenu.jsx";
import { Link } from "react-router-dom";
import Footer from "../../blocks/Footer.jsx";

const FAQPage = () => {
  // Define the FAQ data in a JSON object
  const faqData = [
    {
      question: "Vad är hunddagis.info?",
      answer: (
        <p>
          hunddagis.info är en sökplattform som hjälper hundägare att hitta hunddagis i Sverige. Vi erbjuder detaljerad information om hunddagis i Stockholm, inklusive tjänster, priser och recensioner, för att göra det enklare att välja rätt vård.
        </p>
      )
    },
    {
      question: "Hur hittar jag hunddagis nära mig?",
      answer: (
        <p>
          Vår sökfunktion gör att du kan filtrera hunddagis efter stad (just nu fokuserat på Stockholm), tjänster som erbjuds (t.ex. dagis, hundhotell, kurser, behandling, veterinär, butik, deltid, rehabilitering, etc.), pris och recensioner.
        </p>
      )
    },
    {
      question: "Vilka tjänster erbjuder hunddagis?",
      answer: (
        <p>
          De flesta hunddagis erbjuder en rad olika tjänster, inklusive heldags- eller deltidsvård, trimning, veterinärvård och träningskurser.
        </p>
      )
    },
    {
      question: "Hur jämför jag priser för hunddagis?",
      answer: (
        <p>
          Varje hunddagislista på vår plattform inkluderar priset för heltid (5 dagar i veckan) vård. Du kan jämföra dessa priser direkt på resultatlistan eller i detaljvyn för varje center. Priserna kan variera beroende på plats, tjänstealternativ och tillgänglighet.
        </p>
      )
    },
    {
      question: "Är recensionerna pålitliga?",
      answer: (
        <p>
          Ja, vi samlar in recensioner från flera betrodda källor, inklusive Google. Recensionerna kommer från verifierade användare som har haft kontakt med hunddagis.
        </p>
      )
    },
    {
      question: "Hur noggrann är informationen om personal och tjänster?",
      answer: (
        <p>
          Vi samlar in personalinformation och tjänstebeskrivningar från hunddagisens officiella webbplatser. Detta inkluderar namn, roller och kvalifikationer hos personalen samt beskrivningar av de tjänster som erbjuds.
        </p>
      )
    },
    {
      question: "Hur ofta uppdateras informationen?",
      answer: (
        <p>
          Vi strävar efter att hålla informationen så uppdaterad som möjligt. Vi rekommenderar att du kontrollerar centerets officiella webbplats för den mest aktuella informationen eller kontaktar dem direkt.
        </p>
      )
    },
    {
      question: "Kan jag kontakta hunddagis direkt?",
      answer: (
        <p>
          Ja, varje hunddagislista inkluderar kontaktinformation såsom telefonnummer, e-post och länkar till deras officiella webbplats och sociala mediekonton. Du kan även kontrollera deras öppettider och om centeret är öppet just nu.
        </p>
      )
    },
    {
      question: "Vad ska jag tänka på när jag väljer hunddagis?",
      answer: (
        <p>
          När du väljer hunddagis bör du tänka på de tjänster som erbjuds, personalens erfarenhet och kvalifikationer, faciliteterna samt kundrecensioner. Vår plattform gör det enkelt att jämföra alla dessa aspekter på ett och samma ställe.
        </p>
      )
    },
    {
      question: "Hur kan jag lägga till mitt hunddagis på plattformen?",
      answer: (
        <p>
          Om du äger eller driver ett hunddagis och vill bli med på vår plattform, <Link className="text-dogblue hover:underline" to={'/kontakt'} onClick={() => window.scrollTo(0, 0)}>kontakta oss</Link>. Vi granskar din ansökan och lägger till ditt center om det uppfyller våra kriterier.
        </p>
      )
    },
    {
      question: "Använder ni cookies eller spårar användardata?",
      answer: (
        <p>
          Ja, vi använder cookies för att förbättra användarupplevelsen och spåra webbprestanda via verktyg som Google Analytics. Du kan välja att inaktivera cookies i din webbläsare, men detta kan begränsa vissa funktioner.
        </p>
      )
    },
    {
      question: "Kan jag lämna en recension eller ge feedback?",
      answer: (
        <p>
          För närvarande tillåter vi inte direkt inlämning av recensioner via vår plattform. Du kan dock lämna recensioner på tredjepartsplattformar som Google eller Facebook, och dessa kommer att visas på vår webbplats.
        </p>
      )
    },
  ];

  // State to track which FAQ items are open
  const [openFAQ, setOpenFAQ] = useState(null);

  // Function to toggle FAQ visibility
  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index); // Toggle open state
  };

  const plusIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>
  );

  const minusIcon = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15" />
    </svg>
  );

  return (
    <div>
      <div className="z-10 w-full bg-white">
        <NavMenu></NavMenu>
      </div>

      <div className="min-h-screen bg-white pb-20 text-gray-800">
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="relative flex h-full justify-center md:justify-start">
            <div className="mt-4 w-full max-w-[860px] sm:mt-10 md:max-w-full lg:mt-12">

              <h1 className="mb-14 text-5xl font-bold">Vanliga Frågor (FAQ)</h1>

              {/* FAQ Section */}
              <div className="space-y-6">
                {faqData.map((faq, index) => (
                  <div key={index} className="border-b mb-4 pb-4">
                    <div
                      className="flex justify-between cursor-pointer"
                      onClick={() => toggleFAQ(index)} // Toggle on click
                    >
                      <h2 className="text-xl font-bold my-2 text-gray-900">
                        {index + 1}. {faq.question}
                      </h2>
                      <div>{openFAQ === index ? minusIcon : plusIcon}</div>
                    </div>
                    <div className={`text-gray-700 text-lg ${openFAQ === index ? "" : "hidden"}`}>
                      {faq.answer}
                    </div>
                  </div>
                ))}
              </div>

            </div>

            <div className="sticky top-[4rem] mb-20 ml-12 mt-[-8rem] hidden h-max min-w-[250px] rounded-xl bg-transparent p-[1.5rem] shadow-none lg:block xl:ml-32"></div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default FAQPage;