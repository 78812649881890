import React, { useRef, useState, useEffect } from "react";

const TagList = ({ services, exploded }) => {
  if(!exploded) exploded = false;
  const [expanded, setExpanded] = useState(false);
  const [visibleTags, setVisibleTags] = useState([]);
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);
  const containerRef = useRef(null);

  const tags = [];

  if(services.kurser){ tags.push("kurser");}
  if(services.behandling){ tags.push("behandling");}
  if(services.veterinär){ tags.push("veterinär");}
  if(services.butik){ tags.push("butik");}
  if(services.rehabilitering){ tags.push("rehabilitering");}
  if(services.hundhotell){ tags.push("hundhotell");}
  if(services.deltid){ tags.push("deltid");}
  if(services.hunddagis){ tags.push("hunddagis");}
  if(services.other){ tags.push(...services.other);}

  useEffect(() => {
    if (expanded) {
      setVisibleTags(tags);
      setHiddenTagsCount(0);
    } else {

      if(exploded){
        setVisibleTags(tags);
        setHiddenTagsCount(0);
      }else{
        calculateVisibleTags();
      }
    }
  }, [expanded]);

  const calculateVisibleTags = () => {
    const container = containerRef.current;
    if (!container) return;

    const containerWidth = container.offsetWidth;
    let totalWidth = 0;
    let visibleTagsTemp = [];
    let hiddenCount = 0;

    for (let i = 0; i < tags.length; i++) {
      const tagWidth = getTextWidth(tags[i]);
      if (totalWidth + tagWidth > containerWidth) {
        hiddenCount = tags.length - i;
        break;
      }
      totalWidth += tagWidth;
      visibleTagsTemp.push(tags[i]);
    }

    if (hiddenCount > 0) {
      const moreTagWidth = getTextWidth(`+${hiddenCount}`);
      if (totalWidth + moreTagWidth > containerWidth) {
        while (
          totalWidth + moreTagWidth > containerWidth &&
          visibleTagsTemp.length > 0
        ) {
          totalWidth -= getTextWidth(visibleTagsTemp.pop());
          hiddenCount++;
        }
      }
    }

    setVisibleTags(visibleTagsTemp);
    setHiddenTagsCount(hiddenCount);
  };

  const getTextWidth = (text) => {
    // Approximate text width using a fixed character width (average for a sans-serif font).
    return text.length * 8 + 24; // 8px per character + (12px + 12px) for padding + 8px gap
  };

  return (
    <div ref={containerRef} className="flex flex-wrap gap-2 overflow-hidden">
      {visibleTags.map((tag, index) => (
        <span
          key={index}
          className="mb-1 flex select-none items-center whitespace-nowrap rounded-lg
    bg-indigo-50 px-2 py-1.5 text-sm font-bold text-dogblue sm:px-3 sm:py-1.5 sm:text-base"
        >
          <span className="capitalize">{tag}</span>
        </span>
      ))}
      {hiddenTagsCount > 0 && !expanded && (
        <button
          onClick={() => setExpanded(true)}
          className="mb-1 flex select-none items-center whitespace-nowrap rounded-lg
          text-white px-2 py-1.5 text-sm font-bold bg-indigo-500 sm:px-3 sm:py-1.5 sm:text-base"
        >
          +{hiddenTagsCount}
        </button>
      )}
    </div>
  );
};

export default TagList;
