import React, { useState, useEffect, useRef, useCallback } from "react";
import { renderToStaticMarkup } from 'react-dom/server';
import { Link } from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import { Switch } from "@material-tailwind/react";

import BookingCard from "src/blocks/BookingCard.jsx";
import NavMenu from "src/blocks/NavMenu/NavMenu.jsx";
import Footer from "src/blocks/Footer.jsx";
import DefaultMarker from "src/components/DefaultMarker.jsx";
import FloatingDetails from "src/blocks/FloatingDetails.jsx";
import Paw from "src/assets/Paw.jsx";

import { Filters } from "src/views/details/filters.jsx";
import { filterData } from "src/views/details/filters.jsx";

// const BookingCard = React.lazy(() => import('src/blocks/BookingCard.jsx'));
// const NavMenu = React.lazy(() => import('src/blocks/NavMenu/NavMenu.jsx'));
// const Footer = React.lazy(() => import('src/blocks/Footer.jsx'));
// const DefaultMarker = React.lazy(() => import('src/components/DefaultMarker.jsx'));
// const FloatingDetails = React.lazy(() => import('src/blocks/FloatingDetails.jsx'));
// const Paw = React.lazy(() => import('src/assets/Paw.jsx'));

import "./ListMap.css";

// import DataList from "../db/DataList.jsx";

import { Button } from "@material-tailwind/react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

function FitBounds({ data, showMap }) {
  const map = useMap();
  
  useEffect(() => {
    if (data.length > 0) {
      const validData = data.filter(item => item.coord && item.coord.latitude !== -1);
      if (validData.length > 0) {
        const bounds = L.latLngBounds(validData.map(item => [item.coord.latitude, item.coord.longitude]));
        const padding = {
          paddingTopLeft: [60, 60],
          paddingBottomRight: [60, 60]
        };
        map.fitBounds(bounds, padding);
      }
    }
  }, [data, map, showMap]);
  
  

  return null;
}

function useThrottledWindowResize(timeout) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    let timeoutId;

    const handleResize = () => {
      if (!timeoutId) {
        timeoutId = setTimeout(() => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
          timeoutId = null;
        }, timeout);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [timeout]);

  return windowSize;
}


function ListMap() {
  const windowSize = useThrottledWindowResize(500);
  const [showMap, setShowMap] = useState(true);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [openedPopup, setOpenedPopup] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [showPrices, setShowPrices] = useState(false);
  
  // default average coords: stockholm
  const [avgCoords, setAvgCoords] = useState([59.33959433962265, 18.022895047169808]);

  // manipulate data with filter component
  const { data: rawData } = useLoaderData();
  const [valueLocation, setValueLocation] = useState("stockholm");
  const [valueService, setValueService] = useState("alla");
  const [valueSort, setValueSort] = useState("popular");

  const [isSticky, setIsSticky] = useState(false);

  const stickyRef = useRef(null);
  const mapRef = useRef();
	const markerRef = useRef(null)
  const cardRefs = useRef([]);

  // Sticky header change background color on scroll
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0,
        rootMargin: '-1px 0px 0px 0px',  // Adjust the root margin to fit your needs
      }
    );

    if (stickyRef.current) {
      observer.observe(stickyRef.current);
    }

    return () => {
      if (stickyRef.current) {
        observer.unobserve(stickyRef.current);
      }
    };
  }, []);

  let data = filterData(rawData, valueLocation, valueService, valueSort);
  
  // Lazy loading
  // const firstNElements = 40;
  // const incrementElements = 10;
  // const [visibleData, setVisibleData] = useState(data.slice(0, firstNElements));
  // const { ref, inView } = useInView({
  //   threshold: 0,
  //   triggerOnce: false,
  // });

  // // lazy loading - on first render
  // useEffect(() => {
  //   data = filterData(rawData);
  //   if (inView) {
  //     setVisibleData((prev) => {
  //       const newLength = prev.length + incrementElements;
  //       return data.slice(0, newLength);
  //     });
  //   }
  // }, [inView, data]);

  // // lazy loading - used when setting filters 
  // useEffect(() => {
  //   let visibleData = filterData(rawData).slice(0, firstNElements);
  //   setVisibleData(visibleData);
  //   if(window.scrollY >= 288){
  //     window.scrollTo(0, 288);
  //   }
  // }, [valueSort, valueService, valueLocation]);
  // // END Lazy loading


  const CardButtons = ({id, item}) => {
    const handleClick = () => {
      setOpenedPopup(item);
      setShowMap(false);
      setScrollPosition(window.scrollY);
    };
    const centerMap = () => {
      if (hoveredItem && hoveredItem.metadata.coord && hoveredItem.metadata.coord.latitude !== -1 && mapRef.current) {
        const map = mapRef.current;
        const markerLatLng = L.latLng(hoveredItem.metadata.coord.latitude, hoveredItem.metadata.coord.longitude);
        let bounds = map.getBounds();
        const delta = 0.05;
        let smallerBounds = L.latLngBounds([bounds.getNorth() - delta, bounds.getEast() - delta], [bounds.getSouth() + delta, bounds.getWest() + delta]);
        if (!smallerBounds.contains(markerLatLng)) {
          map.setView(markerLatLng, map.getZoom(), {
            animate: true
          });
        }
      }
    }
    return (
      <div className="flex flex-row items-center justify-between">
        <Button size="md" fullWidth={true} variant="outlined" className="mr-1 sm:mr-3 lg:hidden flex-1 px-0" onClick={handleClick}>
          <span className="ml-1">Hitta på Karta </span>
        </Button>

        <div className="flex flex-row items-center justify-between w-full flex-1">
          <Button size="md" fullWidth={true} variant="outlined" color="black" className="hidden lg:flex items-center justify-center mr-1 sm:mr-3 lg:flex-1 px-0" onClick={centerMap}>
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-mb-1 -mt-1 h-5 w-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
            </svg> */}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-mb-1 -mt-1 mr-1 size-5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
            </svg>
            <span className="ml-1 text-[16px]">Hitta på Karta </span>
          </Button>
          <Link to={`hunddagis/${id}`} className="w-full ml-1 sm:ml-3 md:ml-0 flex-1 flex items-center justify-center" aria-label={`Läs mer om ${item.centerName}`}>
            <Button size="md" fullWidth={true} variant="outlined" color="black">
              <span className="text-[16px]">Läs mer</span>
            </Button>
          </Link>
        </div>
      </div>
    );
  };

  const getAverageLocation = useCallback((data) => {
    const validData = data.filter(item => item.metadata.coord && item.metadata.coord.latitude !== -1);
    let totalLat = 0;
    let totalLong = 0;
    validData.forEach((item) => {
      totalLat += item.metadata.coord.latitude;
      totalLong += item.metadata.coord.longitude;
    });
    return { latitude: totalLat / validData.length, longitude: totalLong / validData.length };
  }, []);
  
  // useEffect(() => {
  //   if (data.length > 0) {
  //     setIsLoading(false);
  //     const avgCoordsValues = data.length > 0 ? getAverageLocation(data) : { latitude: 0, longitude: 0 };
  //     setAvgCoords([avgCoordsValues.latitude, avgCoordsValues.longitude]);
  //   }
  // }, [data]);

  useEffect(() => {
    if (mapRef.current && data.length > 0) {
      const validData = data.filter(item => item.metadata.coord && item.metadata.coord.latitude !== -1);
      if (validData.length > 0) {
        const map = mapRef.current;
        map.invalidateSize();
        const bounds = L.latLngBounds(validData.map(item => [item.metadata.coord.latitude, item.metadata.coord.longitude]));
        map.fitBounds(bounds);
      }
    }
    if (windowSize.width > 959){
      setShowMap(true);
    }
    if (data.length > 0) {
      setIsLoading(false);
    }
  }, [data, showMap, windowSize]);

  const toggleView = () => {
    setShowMap(!showMap);
    if (showMap) {
      setScrollPosition(window.scrollY);
    } else {
      window.scrollTo(0, scrollPosition);
    }
  };

  useEffect(() => {
    if (showMap) {
      // Restore the scroll position when switching back to list view
      window.scrollTo(0, scrollPosition);
    }
  }, [showMap, scrollPosition]);

  // Center the map when the hovered item changes
  // useEffect(() => {
  //   if (hoveredItem && hoveredItem.metadata.coord && hoveredItem.metadata.coord.latitude !== -1 && mapRef.current) {
  //     const map = mapRef.current;
  //     const markerLatLng = L.latLng(hoveredItem.metadata.coord.latitude, hoveredItem.metadata.coord.longitude);
  //     let bounds = map.getBounds();
  //     const delta = 0.05;
  //     let smallerBounds = L.latLngBounds([bounds.getNorth() - delta, bounds.getEast() - delta], [bounds.getSouth() + delta, bounds.getWest() + delta]);
  //     if (!smallerBounds.contains(markerLatLng)) {
  //       map.setView(markerLatLng, map.getZoom(), {
  //         animate: true
  //       });
  //     }
  //   }
  // }, [hoveredItem]);
  
  
  useEffect(() => {
    if (openedPopup && mapRef.current) {
      const map = mapRef.current;
      map.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.getLatLng().equals([openedPopup.metadata.coord.latitude, openedPopup.metadata.coord.longitude])) {
          layer.openPopup();
        } else {
          layer.closePopup();
        }
      });
    }
  }, [openedPopup]);

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    // setHoveredItem(null);
  };

  const handleMarkerClick = (item) => {;
    setHoveredItem(item); // to be replaced
    setOpenedPopup(item);
    const cardIndex = data.findIndex(d => d.id === item.id);
    if (cardRefs.current[cardIndex]) {
      cardRefs.current[cardIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const createCustomIcon = (svgString) => {
    return L.divIcon({
      html: svgString,
      iconSize: [68, 31],
      iconAnchor: [34, 31],
      popupAnchor: [0, -31],
      className: ''
    });
  };

  const togglePriceRatingView = () => {
    setShowPrices(!showPrices);
  }

  return (
    <div>
      {/* <div className="relative sm:fixed top-0 z-10 w-full bg-gray-50"> */}
      <div className="sticky top-0 z-50 w-full bg-gray-50">
        <NavMenu />
        {/* {visibleData.length} */}
      </div>

      <div className={`w-full bg-[#f0f0f0] ${showMap ? '' : 'hidden'}`} ref={stickyRef}>
        <div className="mx-auto max-w-4xl lg:max-w-6xl">
          <div className="flex flex-col w-full px-4 lg:px-8 pt-6 md:pt-14 lg:pt-20 md:mx-0 ">
            <h1 className="text-2xl font-black md:text-4xl lg:text-5xl text-dogblue">
              Hunddagis i Stockholm
            </h1>
            <h2 className="text-lg font-black leading-normal mt-2">Sök hunddagis</h2>
          </div>
        </div>
      </div>
      <div className={`"w-full ${isSticky ? 'bg-gray-50' : 'bg-[#f0f0f0]'} sticky top-20 z-50 ${showMap ? '' : 'hidden'}`} >
        <div className="mx-auto max-w-4xl lg:max-w-6xl">
          <div className="flex w-full items-center p-4 lg:px-8">
            <div className="w-full sticky ">
              <Filters
                valueLocation={valueLocation}
                setValueLocation={setValueLocation}
                valueService={valueService}
                setValueService={setValueService}
                valueSort={valueSort}
                setValueSort={setValueSort}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`"w-full bg-[#f0f0f0] ${showMap ? '' : 'hidden'}`}>
        <div className="mx-auto max-w-4xl lg:max-w-6xl">
          <div className="flex w-full items-center pb-4 md:pb-10 lg:pb-16">
          </div>
        </div>
      </div>


      <div className={`bg-gray-50 pt-4 ${showMap ? '' : 'bottom-0 fixed w-full lg:block'}`}>
        <div className={`mx-auto max-w-7xl lg:px-8 ${showMap ? 'px-4' : 'px-0'}`}>
          <div className={`mx-auto ${showMap ? 'sm:mt-10' : ''} max-w-4xl lg:max-w-6xl`}>

            {/* {showMap && (
              <div className="sticky top-0 sm:top-20 z-50 bg-gray-50 pb-4 pt-2 -ml-4 -mr-4 px-4">
                <Filters
                  valueLocation={valueLocation}
                  setValueLocation={setValueLocation}
                  valueService={valueService}
                  setValueService={setValueService}
                  valueSort={valueSort}
                  setValueSort={setValueSort}
                />
              </div>
            )} */}

            <div className="relative">
              <div className="flex h-full w-full justify-between">
                <div className={`mr-0 flex-col w-full lg:mr-4 ${showMap ? 'flex' : 'hidden lg:flex'}`}>
                  {/* {visibleData.map((item, i) => ( */}
                  {data.map((item, i) => (
                    <div
                      key={i}
                      onMouseEnter={() => handleMouseEnter(item)}
                      onMouseLeave={handleMouseLeave}
                      ref={el => cardRefs.current[i] = el}
                    >
                      <BookingCard key={item.id} data={item} linkTo={`hunddagis/${item.id}`} isHighlighted={hoveredItem === item} actions={<CardButtons id={item.id} item={item} />} />
                    </div>
                  ))}
                  {/* {visibleData.length < data.length && (
                    <li ref={ref} className="p-4 bg-gray-200 shadow rounded">
                      Loading...
                    </li>
                  )} */}
                </div>

                <div className={`sticky lg:ml-4 w-full ${showMap ? 'hidden lg:block' : ' block mt-20 sm:mt-0'}`} style={{ height: `${showMap ? "calc(-190px + 100vh)" : "calc(-88px + 100vh)"}`, top: `${showMap ? "165px" : "88px"}` }}>
                  {isLoading ? (
                    <div className="bg-gray-200 flex flex-col h-full w-full items-center justify-center text-gray-400 animate-pulse">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-24 h-24">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
                      </svg>
                      <div className="text-2xl mt-2">Kartan laddas...</div>
                    </div>
                  ) : (
                    <MapContainer
                      center={[59.334591, 18.063240]}
                      // center={avgCoords}
                      zoom={11}
                      className="rounded-lg"
                      style={{ height: "100%", width: "100%" }}
                      scrollWheelZoom={true} ref={mapRef} whenCreated={(mapInstance) => { mapRef.current = mapInstance }}>
                      {/* <TileLayer url="https://tiles-eu.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}@2x.png" /> */}
                      {/* <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png" /> */}
                      {/* <TileLayer url="https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png" /> */}

                      {/* <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png" /> */}
                      {/* <TileLayer url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png" /> */}
                      {/* <TileLayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" /> */}
                      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png" />
                      {/* {visibleData.filter(item => item.metadata.coord && item.metadata.coord.latitude !== -1).map((item, i) => { */}
                      {data.filter(item => item.metadata.coord && item.metadata.coord.latitude !== -1).map((item, i) => {
                        const defaultMarkerSvg = renderToStaticMarkup(<DefaultMarker showPaw={!showPrices} value={showPrices ? item.pricing.fullprice : item.metadata.totalScore} highlighted={false} />);
                        const highlightMarkerSvg = renderToStaticMarkup(<DefaultMarker showPaw={!showPrices} value={showPrices ? item.pricing.fullprice : item.metadata.totalScore} highlighted={true} />);
                        let markerJSX =
                          <Marker
                              key={item.id}
                              ref={markerRef}
                              position={[item.metadata.coord.latitude, item.metadata.coord.longitude] }
                              icon={hoveredItem === item ? createCustomIcon(highlightMarkerSvg) : createCustomIcon(defaultMarkerSvg)}
                              eventHandlers={{ click: () => handleMarkerClick(item) }}
                            >
                              <Popup className="block lg:hidden hunddagis-popup bg-white border border-gray-300 overflow-hidden cursor-pointer rounded-lg text-gray-800 leading-normal shadow-md">
                                <div className="flex flex-col">
                                  <div className="text-2xl leading-tight font-black p-0 mr-8">{item.centerName}</div>
                                  <div className="text-base text-gray-600 leading-relaxed p-0 my-1">{item.metadata.address}</div>
                                  <div className="flex flex-row items-end my-2">
                                    <div className="flex flex-row items-center">
                                      <Paw size={7} extraClasses={"mr-1"} />
                                      <div className="text-3xl font-bold text-gray-900">{item.metadata.totalScore}</div>
                                    </div>
                                    <div className="text-base ml-2 text-gray-600">{item.reviews.length} recesioner</div>
                                  </div>
                                  {item.pricing && (
                                    <FloatingDetails pricing={item.pricing} name={item.centerName} services={item.services} exploded={false} />
                                  )}
                                  <Link to={`hunddagis/${item.id}`} className="w-full mt-5" aria-label={`Läs mer om ${item.centerName}`}>
                                    <Button size="sm"  fullWidth={true} variant="outlined">
                                      <span className="text-[16px]">Läs mer</span>
                                    </Button>
                                  </Link>
                                </div>
                              </Popup>
                            </Marker>

                        return (
                          item.metadata.coord.latitude > 0 ? markerJSX : null
                        );
                      })}
                      {/* <FitBounds data={data} showMap={showMap} /> */}
                      <div class="absolute z-[400] top-0 left-0 right-0 w-full text-lg bg-gray-300/50 flex justify-center p-1.5">
                        <Switch size={"md"} label={showPrices ? "Pris" : "Betyg"} checked={showPrices} onChange={togglePriceRatingView} />
                      </div>
                      <FitBounds data={data.filter(item => item.metadata.coord && item.metadata.coord.latitude !== -1)} showMap={showMap} />
                    </MapContainer>
                  )}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-0 fixed left-1/2 translate-x-[-50%] lg:hidden mb-4">
        <Button size="md" color="amber" onClick={toggleView}>
          <div className="flex items-center">
            {showMap ? (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 6.75V15m6-6v8.25m.503 3.498 4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 0 0-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0Z" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 0 1 0 3.75H5.625a1.875 1.875 0 0 1 0-3.75Z" />
              </svg>
            )}
            <div className="ml-4 text-[16px]">{showMap ? 'Karta' : 'Lista'}</div>
          </div>
        </Button>
      </div>
      
      {!showMap ? null : (
        <Footer extraClasses={"bg-[#f0f0f0] mt-20"}></Footer>
      )}
    </div>
  );
}

export default ListMap;
