import { useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import logoLong from "../../assets/logo-long.svg";
import menuIcon from "../../assets/menu-icon.svg";

const products = [
  { name: "Analytics", href: "#" },
  { name: "Engagement", href: "#" },
  { name: "Security", href: "#" },
  { name: "Integrations", href: "#" },
  { name: "Automations", href: "#" },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavMenu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="mx-auto max-w-7xl p-5 lg:px-8">
      <nav
        className="flex items-center justify-between"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <Link to="/" className="-m-1.5 flex flex-row items-center p-1.5">
            <img width="375" height="82" src={logoLong} className="h-12 w-auto" alt="logo" />
          </Link>
        </div>
        <div className="flex">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <img src={menuIcon} className="h-6 w-6" alt="menu icon" aria-hidden="true" />
            {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
          </button>
        </div>
      </nav>

      <Dialog
        className="flex"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-20 z-50" />
        <div
          className="fixed inset-y-0 p-6 right-0 z-50 w-full max-w-md bg-white overflow-y-auto transition-all transform duration-500 ease-in-out"
          style={{ transform: mobileMenuOpen ? "translateX(0)" : "translateX(100%)" }}
        >
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Hunddagis.info</span>
              <img src={logoLong} className="h-8 w-auto" width="146" height="32" alt="logo" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {/* <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Hitta din hunddagis
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none",
                          )}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure> */}
                <a
                  href="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-label="Hitta Hunddagis"
                >
                  Hitta Hunddagis
                </a>
                <a
                  href="/om-oss"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-label="Om oss"
                >
                  Om oss
                </a>
                <a
                  href="/faq"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-label="Vanliga Frågor (FAQ)"
                >
                  Vanliga Frågor (FAQ)
                </a>
                <a
                  href="/villkor"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-label="Användarvillkor"
                >
                  Användarvillkor
                </a>
                <a
                  href="/integritetspolicy"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-label="Integritetspolicy"
                >
                  Integritetspolicy
                </a>
              </div>
              <div className="space-y-2 py-6 col-span-2 md:col-span-6 flex flex-col mb-4 sm:mb-0">
                <div className="mb-2 text-xl font-bold text-dogblue">Är du hunddagisägare?</div>
                <a
                  href="/kontakt"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  aria-label="Kontakta oss"
                >
                  Kontakta oss
                </a>
                <a href="mailto:hej@hunddagis.info" className="font-light opacity-60 hover:opacity-100 cursor-pointer mb-1" aria-label="Email">hej@hunddagis.info</a>
                {/* <a href="#" className="font-light opacity-60 hover:opacity-100 cursor-pointer mb-1" aria-label="Telefon">070-123 45 67</a> */}
              </div>
              {/* <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </Dialog>
    </header>
  );
}
