import React from "react";
import { HashLink } from "react-router-hash-link";
import CustomRatingAlt from "../../blocks/CustomRatingAlt.jsx";

const RatingAlt = ({ info }) => {
  let totalRating = info.metadata?.totalScore ? info.metadata.totalScore : 0;
  return (
    <>
      <div className="mb-5 flex flex-col md:flex-row">
        <div className="mb-3 md:mb-0 mr-4 text-2xl font-black sm:text-3xl flex items-center">Betyg</div>
        <div className="flex flex-col md:flex-row ml-2 md:ml-0">
          <div className="flex flex-row">
            <CustomRatingAlt className={"rotate-[30deg] w-auto h-auto mr-2"} size={7} msize={10} value={Math.round(parseFloat(totalRating))} />
            <div className="flex flex-row mb-2 sm:mb-0">
              <div className="flex items-center text-5xl font-bold mr-1 sm:mr-3">
                {info.metadata?.totalScore}
              </div>
            </div>
          </div>
          
          <div className="flex items-end">
            <HashLink
              smooth
              to="#reviews"
              className="text-base text-indigo-500 underline mb-1"
            >
              {info.reviews?.length} recensioner
            </HashLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default RatingAlt;
