import React from "react";
import NavMenu from "../../blocks/NavMenu/NavMenu.jsx";
import Footer from "../../blocks/Footer.jsx";

const PrivacyPolicy = () => {
  return (
    <div>
      <div className="z-10 w-full bg-white">
        <NavMenu></NavMenu>
      </div>

      <div className="min-h-screen bg-white pb-20 text-gray-800">
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="relative flex h-full justify-center md:justify-start">
            <div className="mt-4 w-full max-w-[860px] sm:mt-10 md:max-w-full lg:mt-12">
              <h1 className="mb-14 text-5xl font-bold">Integritetspolicy</h1>
              <p className="mb-4 text-lg">Senast uppdaterad: 2024-10-07</p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                1. Introduktion
              </h2>
              <p className="mb-4 text-lg">
                Denna integritetspolicy beskriver hur hunddagis.info (“vi”,
                “vår” eller “oss”) samlar in, använder och skyddar din
                personliga information när du använder vår webbplats
                (“Tjänsten”). Genom att använda Tjänsten samtycker du till
                insamling och användning av information i enlighet med denna
                policy.
              </p>
              <p className="mb-4 text-lg">
                Vi är fast beslutna att skydda din integritet och säkerställa
                att all information vi samlar in hanteras på ett säkert och
                ansvarsfullt sätt.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                2. Vilken information vi samlar in
              </h2>
              <p className="mb-4 text-lg">
                Vi kan samla in följande information:
              </p>
              <ul className="mb-6 list-outside list-disc pl-6 text-lg">
                <li>
                  Användningsdata: Information om hur du använder vår webbplats
                  (t.ex. vilka sidor som besöks, tid spenderad och
                  interaktioner).
                </li>
                <li>
                  Cookies: Små textfiler som placeras på din enhet för att spåra
                  dina preferenser och aktiviteter på webbplatsen.
                </li>
              </ul>
              <p className="mb-4 text-lg">
                Vi samlar inte in personligt identifierbar information om du
                inte frivilligt tillhandahåller den (t.ex. när du kontaktar oss
                via e-post eller feedbackformulär).
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                3. Hur vi använder informationen
              </h2>
              <p className="mb-4 text-lg">
                Vi använder informationen som samlas in via Google Analytics för
                att:
              </p>
              <ul className="mb-6 list-outside list-disc pl-6 text-lg">
                <li>Förstå hur besökare använder vår webbplats.</li>
                <li>
                  Förbättra användarupplevelsen och optimera vår webbplats
                  baserat på användarbeteende.
                </li>
                <li>Spåra och analysera trender i webbtrafiken.</li>
              </ul>
              <p className="mb-4 text-lg">
                Ingen av denna information används för att personligen
                identifiera dig som användare.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                4. Cookies och liknande teknik
              </h2>
              <p className="mb-4 text-lg">
                Vi använder cookies för att förbättra din upplevelse av vår
                webbplats. En cookie är en liten textfil som placeras på din
                enhet när du besöker vår webbplats. De cookies vi använder
                hjälper oss att:
              </p>

              <ul className="mb-6 list-outside list-disc pl-6 text-lg">
                <li>
                  Analysera webbtrafik och användarbeteende via Google
                  Analytics.
                </li>
                <li>
                  Komma ihåg dina inställningar och preferenser för framtida
                  besök.
                </li>
              </ul>
              <p className="mb-4 text-lg">
                Du kan välja att blockera eller radera cookies genom dina
                webbläsarinställningar. Observera att vissa delar av webbplatsen
                kanske inte fungerar korrekt om du väljer att inaktivera
                cookies.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                5. Dela information med tredje part
              </h2>
              <p className="mb-4 text-lg">
                Vi säljer, hyr ut eller delar inte din personliga information
                med tredje part, förutom när det är nödvändigt för att:
              </p>
              <ul className="mb-6 list-outside list-disc pl-6 text-lg">
                <li>
                  Använda tjänster som Google Analytics för att analysera
                  webbtrafik.
                </li>
                <li>
                  Uppfylla lagliga skyldigheter eller svara på juridiska
                  processer.
                </li>
              </ul>
              <p>
                Google kan komma att samla in data och behandla informationen
                för sina egna syften. För mer information om hur Google använder
                data som samlas in via våra webbplatser, se{" "}
                <a
                  href="https://policies.google.com/privacy?hl=sv"
                  target="_blank"
                >
                  Googles sekretesspolicy
                </a>
                .
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                6. Hur vi skyddar din information
              </h2>
              <p className="mb-4 text-lg">
                Vi vidtar alla rimliga tekniska och organisatoriska åtgärder för
                att skydda din information från obehörig åtkomst, ändring,
                avslöjande eller förstörelse.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                7. Dina rättigheter
              </h2>
              <p className="mb-4 text-lg">
                Enligt GDPR har du flera rättigheter gällande dina
                personuppgifter. Du har rätt att:
              </p>
              <ul className="mb-6 list-outside list-disc pl-6 text-lg">
                <li>
                  Begära åtkomst: Du kan begära en kopia av de personuppgifter
                  vi har om dig.
                </li>
                <li>
                  Begära rättelse: Du kan begära att vi rättar felaktig eller
                  ofullständig information.
                </li>
                <li>
                  Begära radering: Du kan begära att vi tar bort dina
                  personuppgifter, under förutsättning att det inte finns någon
                  laglig grund för oss att fortsätta behandla dem.
                </li>
                <li>
                  Begränsa behandling: Du kan begära att vi begränsar
                  behandlingen av dina personuppgifter i vissa situationer.
                </li>
                <li>
                  Invända mot behandling: Du kan invända mot behandlingen av
                  dina personuppgifter i vissa fall, exempelvis vid direkt
                  marknadsföring.
                </li>
                <li>
                  Dataportabilitet: Du har rätt att begära överföring av dina
                  personuppgifter till en annan tjänst.
                </li>
              </ul>
              <p className="mb-4 text-lg">
                Om du vill utöva någon av dessa rättigheter kan du kontakta oss
                via kontaktinformationen som anges i denna policy.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                8. Användning av Google Analytics
              </h2>
              <p className="mb-4 text-lg">
                Vi använder Google Analytics för att samla in anonymiserad data
                om hur besökare interagerar med vår webbplats, till exempel
                vilka sidor som besöks och hur länge besökare stannar på
                webbplatsen. Denna information används för att förbättra
                användarupplevelsen och optimera webbplatsens funktionalitet.{" "}
              </p>
              <p>
                Google Analytics använder cookies för att samla in denna
                information. Cookies är små textfiler som lagras på din enhet.
                Den information som genereras av dessa cookies överförs och
                lagras av Google på servrar i USA. Google kan överföra denna
                information till tredje parter där det krävs enligt lag, eller
                där sådana tredje parter behandlar informationen på uppdrag av
                Google.
              </p>
              <p>
                Du kan välja att avstå från att använda Google Analytics genom
                att installera{" "}
                <a
                  href="https://tools.google.com/dlpage/gaoptout"
                  target="_blank"
                >
                  Google Analytics Opt-out Browser Add-on
                </a>
                .
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                9. Cookies
              </h2>
              <p className="mb-4 text-lg">
                Vi använder cookies för att förbättra din upplevelse på vår
                webbplats och för att analysera användarbeteenden med hjälp av
                Google Analytics. Cookies kan innehålla information om din
                webbläsare, IP-adress, och hur du använder webbplatsen.
              </p>
              <p className="mb-4 text-lg">
                Om du inte vill acceptera cookies kan du justera dina
                webbläsarinställningar för att blockera cookies eller meddela
                dig när cookies används. Observera att blockering av cookies kan
                påverka vissa funktioner på webbplatsen.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                10. Dataskydd
              </h2>
              <p className="mb-4 text-lg">
                Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att
                skydda dina personuppgifter från obehörig åtkomst, förlust,
                ändring eller radering. Endast behörig personal har åtkomst till
                dina uppgifter, och de behandlas med sekretess.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                11. Tredjepartslänkar
              </h2>
              <p className="mb-4 text-lg">
                Vår webbplats kan innehålla länkar till tredjepartswebbplatser.
                Vi ansvarar inte för innehåll, sekretesspolicyer eller praxis på
                dessa webbplatser. Vi rekommenderar att du granskar deras
                integritetspolicy innan du använder deras tjänster.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                12. Ändringar i denna policy
              </h2>
              <p className="mb-4 text-lg">
                Vi kan uppdatera denna integritetspolicy från tid till annan.
                Eventuella ändringar kommer att publiceras på denna sida, och
                det är ditt ansvar att granska policyn regelbundet för att hålla
                dig informerad om ändringar.
              </p>

              <h2 className="mb-4 mt-8 text-2xl font-semibold text-gray-800">
                13. Kontaktinformation
              </h2>
              <p className="mb-4 text-lg">
                Om du har några frågor eller funderingar kring dessa
                Användarvillkor, vänligen kontakta oss på e-post:{" "}
                <a href="mailto:hej@hunddagis.info" className="text-dogblue hover:underline">hej@hunddagis.info</a>
              </p>
            </div>

            <div className="sticky top-[4rem] mb-20 ml-12 mt-[-8rem] hidden h-max min-w-[250px] rounded-xl bg-transparent p-[1.5rem] shadow-none lg:block xl:ml-32"></div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicy;
