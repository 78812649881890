function Paw ({ size, msize, rotate, extraClasses, fill }) {
  let fillColor = "#EA2A70";
  // if(msize != null){
  //   msize = size;
  // }
  if(fill != null){
    fillColor = fill;
  }
  return (
    <svg
      width="35"
      height="29"
      viewBox="0 0 35 29"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      className={`h-${size} w-${size} md:h-${msize} md:w-${msize} ${rotate ? 'rotate-[30deg]' : ''} ${extraClasses}`}
    >
      <path
        d="M13.0848 8.21954C15.0727 7.53841 16.1327 5.37456 15.4515 3.3866C14.7712 1.39926 12.6077 0.338324 10.6194 1.01959C8.63141 1.70018 7.57143 3.86389 8.25202 5.85192C8.93295 7.84049 11.0964 8.89958 13.0848 8.21954Z"
      />
      <path
        d="M7.0003 14.1422V14.1429C8.14231 12.3782 7.63713 10.0218 5.87346 8.88016C4.10911 7.73918 1.75324 8.24333 0.611294 10.0073V10.008C-0.529965 11.772 -0.0248581 14.1279 1.73888 15.2688C3.50317 16.411 5.8587 15.9063 7.0003 14.1422Z"
      />
      <path
        d="M21.9167 8.21961C23.905 8.89964 26.0684 7.84055 26.7493 5.85191C27.4299 3.86395 26.3699 1.70017 24.382 1.01959C22.3937 0.338317 20.2302 1.39932 19.5499 3.3866C18.8687 5.37463 19.9287 7.53847 21.9167 8.21961Z"
      />
      <path
        d="M17.5006 10.0901C11.4866 10.0901 4.85083 18.4174 6.77186 24.3425C8.62659 30.0631 14.4213 27.7055 17.5006 27.7055C20.5798 27.7055 26.3745 30.0631 28.2293 24.3425C30.1504 18.4174 23.5146 10.0901 17.5006 10.0901Z"
      />
      <path
        d="M34.3902 10.008V10.0073C33.2482 8.24333 30.8925 7.73918 29.1281 8.88016C27.3644 10.0218 26.8593 12.3782 28.0012 14.1429V14.1422C29.1428 15.9063 31.4983 16.411 33.2626 15.2688C35.0263 14.1279 35.5315 11.7721 34.3902 10.008Z"
      />
    </svg>
  );
}

export default Paw;