import React from "react";
// import { Select, Option } from "@material-tailwind/react";
import SelectComponent from "../../components/Select";
import './Filters.css';

export function filterData(data, valueLocation, valueService, valueSort) {
  // Apply location filter
  if (valueLocation !== "stockholm") {
    // data = data.filter(item => item.location === valueLocation);
  }

  // Apply service filter
  if(valueService !== "alla") {
    data = data.filter(hunddagis => {
      let servicesArray = false;
      console.log("hunddagis.services", hunddagis.services);
      if(hunddagis.services !== undefined) {
        servicesArray = hunddagis.services[valueService];
      }
      console.log("servicesArray", servicesArray);
      return servicesArray;
    });
  }
  // data = data.filter(hunddagis => hunddagis.services["kurser"]);
  // switch (valueService) {
  //   case "kurser":
  //     break;
  
  //   default:
  //     break;
  // }
  // if (valueService !== "alla") {
  //   console.log("valueService", valueService);
  //   // data = data.filter(item => {
  //   //   console.log("item.service", item.service);
  //   //   return item.service === valueService;
  //   // });
  //   // data = data.filter(item => item.service === valueService);
  // }

  // Apply sorting
  if (valueSort === "pris-hl") {
    data = data.sort((a, b) => {
      let pricing_a = 9999;
      let pricing_b = 9999;
      if (a.pricing != null) {
        pricing_a = a.pricing.fullprice;
      }
      if (b.pricing != null) {
        pricing_b = b.pricing.fullprice;
      }
      return pricing_a - pricing_b;
    });
  } else if (valueSort === "pris-lh") {
    data = data.sort((a, b) => {
      let pricing_a = 9999;
      let pricing_b = 9999;
      if (a.pricing != null) {
        pricing_a = a.pricing.fullprice;
      }
      if (b.pricing != null) {
        pricing_b = b.pricing.fullprice;
      }
      return pricing_b - pricing_a;
    });
  } else if (valueSort === "popular") {
    data = data.sort((a, b) => {
      let a5s = 0;
      let a4s = 0;
      let a3s = 0;
      let a2s = 0;
      let a1s = 0;

      a.reviews.forEach((review) => {
        if (review.stars.includes("5")) {
          a5s += 1;
        } else if (review.stars.includes("4")) {
          a4s += 1;
        } else if (review.stars.includes("3")) {
          a3s += 1;
        } else if (review.stars.includes("2")) {
          a2s += 1;
        } else if (review.stars.includes("1")) {
          a1s += 1;
        }
      });

      let b5s = 0;
      let b4s = 0;
      let b3s = 0;
      let b2s = 0;
      let b1s = 0;

      b.reviews.forEach((review) => {
        if (review.stars.includes("5")) {
          b5s += 1;
        } else if (review.stars.includes("4")) {
          b4s += 1;
        } else if (review.stars.includes("3")) {
          b3s += 1;
        } else if (review.stars.includes("2")) {
          b2s += 1;
        } else if (review.stars.includes("1")) {
          b1s += 1;
        }
      });

      let bStars = ((b5s * 5) + (b4s * 4) + (b3s * 3) + (b2s * 2) + (b1s * 1));
      let aStars = ((a5s * 5) + (a4s * 4) + (a3s * 3) + (a2s * 2) + (a1s * 1));

      return bStars - aStars;
    });
  } else if (valueSort === "rating-hl") {
    data = data.sort((a, b) => b.metadata.totalScore - a.metadata.totalScore || b.reviews.length - a.reviews.length);
  } else if (valueSort === "rating-lh") {
    data = data.sort((a, b) => a.metadata.totalScore - b.metadata.totalScore || b.reviews.length - a.reviews.length);
  }
  return data;
}


export function Filters({ 
  valueLocation, setValueLocation, 
  valueService, setValueService, 
  valueSort, setValueSort 
}) {
  return (
    <div>
      <div className="grid grid-cols-6 gap-x-2 md:gap-x-10">

        <div className="col-span-3 md:col-span-2 select-wrapper">
          <SelectComponent
            placeholder="Var"
            value={valueLocation}
            onChange={(val) => setValueLocation(val)}
            options={[
              {value: "stockholm", label: "Stockholm"},
            ]}
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 opacity-70">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
              </svg>
            }
          />
        </div>

        <div className="col-span-3 md:col-span-2 select-wrapper">
          <SelectComponent
            placeholder="Tjänster"
            value={valueService}
            onChange={(val) => setValueService(val)}
            options={[
              {value: "alla", label: "Alla"},
              {value: "hundhotell", label: "Hundhotell"},
              {value: "kurser", label: "Kurser"},
              {value: "behandling", label: "Behandling"},
              {value: "veterinär", label: "Veterinär"},
              {value: "butik", label: "Butik"},
              {value: "deltid", label: "Deltid"},
              {value: "rehabilitering", label: "Rehabilitering"},
            ]}
            icon={
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 opacity-70">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
              </svg>            
            }
          />
        </div>

        <div className="col-span-6 mt-4 md:mt-0 md:col-span-2 select-wrapper">
          <SelectComponent
            placeholder="Sortera efter"
            value={valueSort}
            onChange={(val) => setValueSort(val)}
            icon={
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5 opacity-70">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" />
            </svg>
            }
            options={[
              {value: "popular", label: "Populär"},
              {value: "rating-hl", label: "Betyg (högt-lågt)"},
              {value: "rating-lh", label: "Betyg (lågt-högt)"},
              {value: "pris-hl", label: "Pris (lågt-högt)"},
              {value: "pris-lh", label: "Pris (högt-lågt)"},
            ]}
          />
        </div>

      </div>
    </div>
  );
}
