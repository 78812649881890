// react component
export default function DefaultMarker({ value, highlighted, showPaw }) {
  return (
    <svg
      width="68"
      height="31"
      viewBox="0 0 68 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={highlighted ? "highlighted-marker" : ""}
    >
      <rect
        x="0.794393"
        y="0.794393"
        width="66.4112"
        height="28.9159"
        rx="7.78505"
        fill={highlighted ? "#3f4eb1" : "white"}
        stroke={highlighted ? "#3f4eb1" : "black"}
        strokeWidth="1.58879"
      />
      {showPaw && (
        <>
        <path d="M17.2341 10.6757C18.489 10.9217 19.706 10.1038 19.9519 8.84882C20.1981 7.59445 19.3807 6.37703 18.1256 6.13108C16.8707 5.88486 15.6537 6.70265 15.4075 7.95747C15.1613 9.21268 15.9793 10.4291 17.2341 10.6757Z" fill={highlighted ? "#FFF" : "#EA2A70"}/>
        <path d="M12.2243 11.9455L12.2241 11.9459C13.3629 11.3633 13.8137 9.96774 13.2315 8.82945C12.6488 7.69127 11.2538 7.24014 10.1153 7.82235L10.1151 7.82271C8.97685 8.40512 8.52622 9.80041 9.10859 10.9384C9.69086 12.0772 11.0859 12.5279 12.2243 11.9455Z" fill={highlighted ? "#FFF" : "#EA2A70"}/>
        <path d="M21.8896 13.363C22.7306 14.3264 24.193 14.4265 25.157 13.5857C26.1206 12.7451 26.2203 11.2822 25.3797 10.3186C24.5391 9.35456 23.0761 9.25545 22.1129 10.0958C21.149 10.9362 21.0492 12.3992 21.8896 13.363Z" fill={highlighted ? "#FFF" : "#EA2A70"}/>
        <path d="M18.9924 13.005C15.8229 11.1751 9.79196 13.5446 9.0015 17.2518C8.23834 20.831 12.0097 21.3517 13.6325 22.2887C15.2553 23.2256 17.5919 26.2313 20.31 23.7808C23.1254 21.2426 22.1619 14.8349 18.9924 13.005Z" fill={highlighted ? "#FFF" : "#EA2A70"}/>
        <path d="M27.918 18.1008L27.9182 18.1005C27.8531 16.8233 26.765 15.8409 25.488 15.9053C24.2111 15.9703 23.2279 17.0585 23.2927 18.336L23.2929 18.3356C23.3578 19.6127 24.4456 20.5954 25.723 20.5303C26.9997 20.4657 27.9827 19.3778 27.918 18.1008Z" fill={highlighted ? "#FFF" : "#EA2A70"}/>
        </>
      )}
      <text
        fill={highlighted ? "white" : "black"}
        xmlSpace="preserve"
        style={{ whiteSpace: "pre" }}
        fontFamily="Reddit Sans"
        fontSize="16.5234"
        fontWeight="600"
        letterSpacing="0em"
      >
        <tspan x={showPaw ? "32.7285" : "6"} y="21.4108">
          {value}{showPaw ? "" : <tspan opacity="0.5" x="47">kr</tspan>}
        </tspan>
      </text>
      <defs>
        <clipPath id="clip0_410_259">
          <rect
            width="16.5234"
            height="16.5234"
            fill="white"
            transform="translate(9.84961 7.30859)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}